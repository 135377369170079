import React from 'react'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import MarineInnovation from '../../Components/MarineStory/MarineInnovation/MarineInnovation'
import MarineStoryOverview from '../../Components/MarineStory/MarineStoryOverview/MarineStoryOverview'
import OtherSections from '../../Components/MarineStory/OtherSections/OtherSections'
import MarineStoryStyled from './MarineStoryStyled'

function MarineStory() {
  return (
    <MarineStoryStyled>
      <Sidebar active="about" />
      <MarineStoryOverview />
      <MarineInnovation />
      <OtherSections />
    </MarineStoryStyled>
  )
}

export default MarineStory
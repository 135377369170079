import React from 'react'
import PhotoReviewStyled from './PhotoReviewStyled'

function PhotoReview() {
  return (
    <PhotoReviewStyled className="photo-review">
      <input type="checkbox" id="photo-review" />
      <label htmlFor="photo-review">Photo Review</label>
    </PhotoReviewStyled>
  )
}

export default PhotoReview
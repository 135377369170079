import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
import ProductScrollListStyled from "./ProductScrollListStyled";

function ProductScrollList({ products: all }) {
  return (
    <ProductScrollListStyled style={{ overflowX: "auto" }}>
      {all.map((product) => (
        <Link to={`/product/${product.productId || product.id}`}>
          <ProductCard
            product={product}
            key={product.productId || product.id}
          />
        </Link>
      ))}
    </ProductScrollListStyled>
  );
}

export default ProductScrollList;

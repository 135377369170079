import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import DetailComponent from '../../Components/Shared/DetailComponent/DetailComponent'
import ReviewDetailStyled from './ReviewDetailStyled'

function ReviewDetail() {
  const review = {
    id: 1,
    writer: "네이버 구매평",
    product: "하루7초 해초샐러드 1Box 7봉지",
    rating: 5,
    date: "2022.07.11",
    subject: "굿!........",
    image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1635832739648_100.jpg",
    message: "많이 달지 않고 맛있어요. 재료 성분이 안전해요."
  }
  return (
    <ReviewDetailStyled>
      <Sidebar />
      <DetailComponent element={review} />
      <Link to="/reviews"><FaChevronLeft size={15} style={{marginRight: '10px'}} /> Return to list</Link>
    </ReviewDetailStyled>
  )
}

export default ReviewDetail
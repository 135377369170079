import styled from "styled-components";
const MarineInnovationStyled = styled.div `
  display: grid;
  grid-template-columns: auto;
  padding: 15px;
  font-family: var(--app-font-family);
  color: var(--app-black);
  line-height: 37px;
  img {
    width: 100%;
    margin: 30px 0px;
    height: 250px !important;
  }
  h2 {
    margin: 25px 0px;
  }
  
`

export default MarineInnovationStyled;
import './MaxWidthLayout.css';

const MaxWidthLayout = ({ children }) => {
    return (
        <div className="layout-container">
            <div className="content-wrapper">
                {children}
            </div>
        </div>
    );
};

export default MaxWidthLayout;
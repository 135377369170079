import styled from "styled-components";
const SignupPageStyled = styled.div `
  padding: 15px;
  h2 {
    font-weight: 500;
  }
  label {
    display: block;
    margin: 15px 0px;
    font-size: 14px;
    color: var(--app-black);
    font-weight: 300;
  }
  form {
    font-size: 16px;
  }
  form>div {
    margin: 20px 0px;
  }
  form .mobile-phone-number div, .zip-code div, .name div, .email div {
    display: flex;
    align-items: center;
    grid-gap: 5px;
  }
  form .address div {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
  }
  form .submit {
    display: flex;
    justify-content: center;
  }
`
export default SignupPageStyled;
import styled from "styled-components";
const MarineStoryOverviewStyled = styled.div `
  background-image: url("https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1580378321413_2500.jpg");
  color: #fff;
  height: 280px;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  h2 {
    font-family: var(--app-font-family);
    font-weight: 900;
    padding-bottom: 20px;
    font-size: 32px;
  }
  p {
    padding-bottom: 20px;
  }
  button {
    background-color: var(--app-black);
    border: none;
    color: #fff;
    padding: 12px 20px;
    border-radius: 20px;
  }
`

export default MarineStoryOverviewStyled;
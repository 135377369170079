import styled from 'styled-components';

const RadioGroupStyled = styled.div`
  display: flex;
  justify-content: center; /* 가운데 정렬 */
  gap: 10px; /* 사이에 10px 마진 */
  margin-top: 10px; /* 위 마진 */
  margin-bottom: 10px; /* 아래 마진 */
`;

export default RadioGroupStyled;
import React from 'react'
import MarineGreyComponentStyled from './MarineGreyComponentStyled'

function MarineGreyComponent({title, content, image, children}) {
  return (
    <MarineGreyComponentStyled>
      <div>
        <h2>{title}</h2>
        {
          children
        }
      </div>
      <div>
        <img src={image} alt="image" />
      </div>
    </MarineGreyComponentStyled>
  )
}

export default MarineGreyComponent
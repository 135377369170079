import React from 'react'
import BlackButtonStyled from './BlackButtonStyled'

function BlackButton({title, width, height, onClick, children, type}) {
  return (
    <BlackButtonStyled width={width} height={height} onClick={()=>onClick()} type={type}>  
      {
        title ? title : children 
      }
    </BlackButtonStyled>
  )
}

export default BlackButton
import React from 'react'
import Question from '../Question/Question'
import QuestionsListStyled from './QuestionsListStyled'

function QuestionsList({questions}) {
  return (
    <QuestionsListStyled>
      {
        questions.map((question)=>(
          <Question question={question} key={question.id} />
        ))
      }
    </QuestionsListStyled>
  )
}

export default QuestionsList
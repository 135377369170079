import React from 'react'
import ProductCard from '../../Shared/ProductCard/ProductCard'
import RelatedProductsStyled from './RelatedProductsStyled'

function RelatedProducts({products}) {
  return (
    <RelatedProductsStyled>
      {
        products.map((product)=>(
          <ProductCard product={product} related={true} key={product.id} />
        ))
      }
    </RelatedProductsStyled>
  )
}

export default RelatedProducts
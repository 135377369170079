import React from 'react'
import SlideBtnStyled from './SlideBtnStyled'

function SlideBtn({active, onClick}) {
  return (
    <SlideBtnStyled active={active}>
      <button onClick={onClick}>

      </button>
    </SlideBtnStyled>
  )
}

export default SlideBtn
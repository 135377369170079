import styled from "styled-components";
const EventCardStyled = styled.div `
  
  background-image: url(${props=>(props.image)});
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  position: relative;
  font-size: 14px;
  padding-top: 15px;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  .inner {
    background-color: rgba(0,0,0,.5);
    color: #fff;
    text-align: center;
    line-height: 20px;
    letter-spacing: 5px;
  }
  img {
    width: 100%;
  }
`

export default EventCardStyled;
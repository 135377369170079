import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import BlackButton from '../../Components/Shared/BlackButton/BlackButton';
import CustomInput from '../../Components/Shared/CustomInput/CustomInput';
import WhiteButton from '../../Components/Shared/WhiteButton/WhiteButton';
import ForgotPasswordStyled from './ForgotPasswordStyled'


function ForgotPassword() {
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/")
  }
  return (
    <ForgotPasswordStyled>
      <Sidebar />
      <div>
        <h2>비밀번호 찾기</h2>
        <form onSubmit={(e)=>handleSubmit(e)}>
          <div className="email">
            <label htmlFor="email">이름</label>
            <CustomInput />
          </div>
          <div className="password">
            <label htmlFor="password">이메일</label>
            <CustomInput />
          </div>
          <div className='buttons'>
            <BlackButton title="이메일 보내기" width="60%" type="submit" />
          </div>
        </form>
      </div>
    </ForgotPasswordStyled>
  )
}

export default ForgotPassword
import React from 'react'
import MarineWhiteComponentStyled from './MarineWhiteComponentStyled'

function MarineWhiteComponent({title, image}) {
  return (
    <MarineWhiteComponentStyled>
      <h2>
        {title}
      </h2>
      <img src={image} alt={image} />
    </MarineWhiteComponentStyled>
  )
}

export default MarineWhiteComponent
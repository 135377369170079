import React from 'react'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import BlackButton from '../../Components/Shared/BlackButton/BlackButton'
import CustomInput from '../../Components/Shared/CustomInput/CustomInput'
import WhiteButton from '../../Components/Shared/WhiteButton/WhiteButton'
import TermsConditions from '../../Components/SignupPage/TermsConditions/TermsConditions'
import SignupPageStyled from './SignupPageStyled'
import axiosInstance from '../../Api/axiosInstance'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { constants } from '../../constants';
import { CustomerDTO } from '../../Models/CustomerDTO'

function SignupPage() {

  const [emailChecked, setEmailChecked] = useState(false); // 이메일 중복체크 여부
  const [isCheckingEmail, setIsCheckingEmail] = useState(false); // 이메일 체크 로딩 상태

  const loginURI = constants.authService + "login";
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    phonenumber: "",
    confirmPassword: "",
    // address: "",
    marketingflag: false,
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    phonenumber: ""
  });

  const [isTermsValid, setIsTermsValid] = useState(false);

  const handleMarketingFlagChange = (value) => {
    setFormData(prev => ({
      ...prev,
      marketingflag: value
    }));
  };

  // 이메일 검사 함수
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

// 전화번호 검사 함수
  const validatePhoneNumber = (number) => {
    return /^\d+$/.test(number);
  };

  // 이메일 중복 확인 함수 추가
  const handleCheckEmail = async (e) => {
    e.preventDefault(); // 폼 제출 방지

    // if (!emailChecked) {
    //   alert("이메일 중복 확인을 해주세요.");
    //   return;
    // }

    // 이메일 입력 확인
    if (!formData.email) {
      alert("이메일을 입력해주세요.");
      return;
    }

    // 이메일 형식 확인
    if (!validateEmail(formData.email)) {
      alert("올바른 이메일 형식이 아닙니다.");
      return;
    }

    try {
      setIsCheckingEmail(true);
      const response = await axiosInstance.post('/customer/check-email', {
        email: formData.email
      });

      if (response.data.available) {
        alert("사용 가능한 이메일입니다.");
        setEmailChecked(true);
      } else {
        alert("이미 사용중인 이메일입니다.");
        setEmailChecked(false);
      }
    } catch (error) {
      console.error("이메일 중복 확인 중 오류 발생:", error);
      alert("이메일 중복 확인에 실패했습니다. 다시 시도해주세요.");
    } finally {
      setIsCheckingEmail(false);
    }
  };

  const handleChange = (e) => {
    // const { name, value } = e.target;
    // setFormData((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));

    const { name, value } = e.target;

    // 휴대폰 번호 검증 추가
    if (name === 'phonenumber') {
      if (value === '' || validatePhoneNumber(value)) {
        setFormData(prev => ({ ...prev, [name]: value }));
      }
      return;
    }

    setFormData(prev => ({ ...prev, [name]: value }));

    // 이메일 검증 추가
    if (name === 'email') {
      if (!validateEmail(value) && value !== '') {
        setErrors(prev => ({
          ...prev,
          email: '올바른 이메일 형식이 아닙니다.'
        }));
      } else {
        setErrors(prev => ({ ...prev, email: '' }));
      }
    }

    // 비밀번호 확인 검증 추가
    if (name === 'confirmPassword' || name === 'password') {
      if (name === 'confirmPassword' && value !== formData.password) {
        setErrors(prev => ({
          ...prev,
          confirmPassword: '비밀번호가 일치하지 않습니다.'
        }));
      } else if (name === 'password' && value !== formData.confirmPassword && formData.confirmPassword) {
        setErrors(prev => ({
          ...prev,
          confirmPassword: '비밀번호가 일치하지 않습니다.'
        }));
      } else {
        setErrors(prev => ({ ...prev, confirmPassword: '' }));
      }
    }
  };

  // DTO 객체 생성
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isTermsValid) {
      alert("필수 약관에 동의해주세요.");
      return;
    }

    // DTO 객체 생성
    const customerDTO = new CustomerDTO(
      formData.email,
      formData.password,
      formData.name,
      formData.phonenumber,
      formData.marketingflag,
      // formData.address
    );

    console.log("회원가입 데이터:", customerDTO);

    // 서버로 데이터 전송 (여기서는 console.log로 대체)
    // 실제 서버와 통신하려면 fetch 또는 axios 등을 사용
    // 예: axios.post('/api/signup', customerDTO)

    alert("회원가입이 완료되었습니다!");
  };

  return (
    <SignupPageStyled>
      <Sidebar />
      <div>
        <h2>회원 가입</h2>
        <form onSubmit={handleSubmit}>
          <div className="email">
            <label htmlFor="email">이메일 *</label>
            <div>
              <CustomInput
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required/>
              {/*<WhiteButton title="중복 확인" width="fit-content"/>*/}
              <WhiteButton
                  type="button"
                  title={isCheckingEmail ? "확인중..." : "중복 확인"}
                  width="fit-content"
                  onClick={handleCheckEmail}
                  disabled={isCheckingEmail}
              />
              {/*중복확인 에러*/}
              {/*{errors.email &&*/}
              {/*    <p className="error-message" style={{color: 'red', fontSize: '0.8rem'}}>*/}
              {/*      {errors.email}*/}
              {/*    </p>*/}
              {/*}*/}
            </div>
            {errors.email && <p className="error-message" style={{color: 'red', fontSize: '0.8rem'}}>{errors.email}</p>}
          </div>
          <div className="name">
            <label htmlFor="name">이름 *</label>
            <CustomInput
                name="name"
                value={formData.name}
                onChange={handleChange}
                required/>
          </div>
          <div className="password">
            <label htmlFor="password">비밀번호 *</label>
            <CustomInput
                type="password"  // 추가된 부분
                name="password"
                value={formData.password}
                onChange={handleChange}
                required/>
          </div>
          <div className="confirm-password">
            <label htmlFor="confirmPassword">비밀번호 확인 *</label>
            <CustomInput
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required/>
            {errors.confirmPassword &&
                <p className="error-message" style={{color: 'red', fontSize: '0.8rem'}}>{errors.confirmPassword}</p>}
          </div>
          <div className="mobile-phone-number">
            <label htmlFor="mobile-phone-number">
              휴대폰 번호 *
            </label>
            <div>
              <CustomInput
                  name="phonenumber"
                  value={formData.phonenumber}
                  onChange={handleChange}
                  required/>
            </div>
          </div>
          {/*<div className="zip-code">*/}
          {/*  <label htmlFor="zip-code">우편번호 *</label>*/}
          {/*  <div>*/}
          {/*    <CustomInput />*/}
          {/*    <WhiteButton width="fit-content" title="검색하기" />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="address">*/}
          {/*  <label htmlFor="address">주소 *</label>*/}
          {/*  <div>*/}
          {/*    <CustomInput/>*/}
          {/*    <CustomInput/>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<TermsConditions/>*/}
          <TermsConditions
              onMarketingFlagChange={handleMarketingFlagChange}
              onValidityChange={setIsTermsValid}
          />
          <div className="submit">
            <BlackButton type="submit" title="가입하기" width="50%"/>
          </div>
          <div className="socials">
            {/*We will add social signup buttons here. 
              they are provided with these social medias
              by default
            */}
          </div>
        </form>
      </div>
    </SignupPageStyled>
  )
}

export default SignupPage;
import styled from 'styled-components';

const RadioButtonStyled = styled.div`
  display: flex;
  flex-direction: row; /* 가로로 정렬 */
  gap: 10px; /* 사이에 10px 마진 */
  align-items: center; /* 수직 정렬 */
`;

export default RadioButtonStyled;

import styled from "styled-components";
const LoginPageStyled = styled.div `
  padding: 15px;
  h2 {
    font-weight: 500;
  }
  label {
    display: block;
    margin: 15px 0px;
    font-size: 14px;
  }
  form {
    font-size: 16px;
  }
  form>div {
    margin: 20px 0px;
  }
    form input {
        width: 100%;
        padding: 8px 12px;  // 상하 8px, 좌우 12px 여백
        font-size: 16px;    // 폼과 같은 글자 크기
        line-height: 1.5;   // 글자 크기의 1.5배 줄 높이
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;  // padding을 width에 포함
    }
  div.other-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    a,span {
      color: var(--app-pink);
      font-weight: 500;
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .buttons button {
    padding: 17px;
    font-size: 1em;
    margin: 10px 0px ;
  }
`
export default LoginPageStyled;
import React, { useState } from "react";
import { useEffect } from "react";
import ThumbnailSliderStyled from "./ThumbnailSliderStyled";

function ThumbnailSlider({ images }) {
  const [img, setImg] = useState(images[0]);
  return (
    <ThumbnailSliderStyled>
      <img src={images[0]} alt="Thumbnail" />
      {/* <div className='images'>
        {
          images.map((image, index)=>(
            <img onClick={()=>setImg(image)} src={image} key={index} />
          ))
        }
      </div> */}
    </ThumbnailSliderStyled>
  );
}

export default ThumbnailSlider;

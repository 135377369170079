import React from 'react'
import { FaAngleRight } from 'react-icons/fa'
import PaginatorStyled from './PaginatorStyled'

function Paginator({numPages}) {
  const pages = []
  for(let i = 0; i < numPages; i++) {
    pages.push(i + 1)
  }
  return (
    <PaginatorStyled>
      { 
       pages.map((page, index)=>(
        <>
        {
          index < 5 && <button key={index}>
            {page}
          </button>
        }
        </>
       )) 
      }
      {
        pages.length > 5 && <button>
          <FaAngleRight />
        </button>
      }
    </PaginatorStyled>
  )
}

export default Paginator
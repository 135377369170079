import styled from "styled-components";
const CustomTextAreaStyled = styled.div `
  padding: 10px 15px;
  border: 1px solid var(--app-border-color);
  font-family: var(--app-font-family);
  textarea {
    height: 80px;
    width: 100%;
    margin: 0;
    padding: 0;
    resize: vertical;
    border: none;
    outline: none;
    font-family: var(--app-font-family);
    font-size: 15px;
  }
  div {
    display: flex;
    justify-content: flex-end;
  }
`
export default CustomTextAreaStyled;
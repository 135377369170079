import React from 'react'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import QuestionsList from '../../Components/Questions/QuestionsList/QuestionsList'
import Paginator from '../../Components/Shared/Paginator/Paginator'
import Search from '../../Components/Shared/Search/Search'
import QuestionsStyled from './QuestionsStyled'

function Questions() {
  const questions = [
    {
      id: 1,
      writer: "네이버 구매평",
      date: "2022.07.11",
      subject: "굿!........"
    },
    {
      id: 1,
      writer: "네이버 구매평",
      date: "2022.07.11",
      subject: "굿!........"
    },
    {
      id: 1,
      writer: "네이버 구매평",
      date: "2022.07.11",
      subject: "굿!........"
    },
  ]
  return (
    <QuestionsStyled>
      <Sidebar active="questions" />
      <QuestionsList questions={questions} />
      <Search />
      <Paginator numPages={3} />
    </QuestionsStyled>
  )
}

export default Questions
import React, { useEffect, useState } from 'react'
import CartItem from '../../Components/CartPage/CartItem/CartItem'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import BlackButton from '../../Components/Shared/BlackButton/BlackButton'
import CartPageStyled from './CartPageStyled'
import Naver from '../../Assets/naver.png'

function CartPage() {


  
  const cartProducts = [
    {
      id: 1,
      productName: "Janudam Seaweed Cup 5oz 10 Packs",
      quantityInCart: 5,
      price: 10000,
      image: "https://contents.sixshop.com/uploadedFiles/116837/product/image_1657858718395.jpg",
      shippingFee: 3000,
      wonFree: 30000
    }
  ]
  return (
    <CartPageStyled>
      <Sidebar />
      {
        cartProducts.map((product)=>(
          <CartItem item={product} />
        ))
      }
      <div className='totals'>
        <div className="total-product">
          <p>total product</p>
          <p>{cartProducts.reduce((prev, curr)=>prev + (curr.price * curr.quantityInCart), 0)} won</p>
        </div>
        <div className="delivery-fee">
          <p>delivery fee</p>
          <p>{cartProducts.reduce((prev, curr)=>prev + curr.shippingFee, 0)} won</p>
        </div>
      </div>
      <div className="sum">
        <p>Sum</p>
        <p>
          {cartProducts.reduce((prev, curr)=>prev + (curr.price * curr.quantityInCart), 0) + cartProducts.reduce((prev, curr)=>prev + curr.shippingFee, 0)} won
        </p>
      </div>
      <div className='place-order'>
        <BlackButton title="place an order" width="100%" />
      </div>      
      <div className="naver">
        <a href="https://m.pay.naver.com/mobile/about">
          <img src={Naver} alt="Pay with naver" />
        </a>
      </div>
    </CartPageStyled>
  )
}

export default CartPage
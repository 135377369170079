import styled from "styled-components";

const ReportPageStyled = styled.div`
  padding: 10px 20px;
  
  h2 {
    font-family: "Nanum Gothic", sans-serif;
    color: var(--app-black);
    margin-bottom: 30px;
    font-weight: bold;
  }

  form {
    max-width: 800px;
    margin: 0 auto;
  }

  .form-group {
    margin-bottom: 25px;
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-family: "Nanum Gothic", sans-serif;
    color: var(--app-black);
  }

  textarea {
    width: 100%;
    min-height: 150px;
    padding: 10px;
    border: 1px solid #ddd;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 15px;
    line-height: 1.5;
    resize: vertical;
  }

  .submit {
    text-align: center;
    margin-top: 40px;
  }
`;

export default ReportPageStyled;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BlackButton from '../../Components/Shared/BlackButton/BlackButton';
import Sidebar from '../../Components/Layout/Sidebar/Sidebar';

const ReportCompleteStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
  padding: 0 20px;

  .message {
    margin-bottom: 40px;
    font-family: "Nanum Gothic", sans-serif;
    line-height: 1.6;
    white-space: pre-line;
  }
`;

function ReportComplete() {
    const navigate = useNavigate();

    return (
        <ReportCompleteStyled>
            <Sidebar />
            <div className="message">
                신고가 접수되었습니다.
                {'\n'}
                검토 후 3일 이내로 이메일을 통해 답변 드리겠습니다.
            </div>
            <BlackButton
                title="홈으로 이동"
                width="200px"
                onClick={() => navigate('/')}
            />
        </ReportCompleteStyled>
    );
}

export default ReportComplete;
import styled from "styled-components";
const PostQuestionStyled = styled.div `
  padding: 15px;
  h2 {
    font-weight: 500;
  }
  label {
    display: block;
    margin: 15px 0px;
    font-size: 14px;
    font-weight: 300;
    color: var(--app-black);
  }
  form {
    font-size: 15px;
    font-weight: 300;
  }
  form>div {
    margin: 20px 0px;
  }
  form .upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--app-border-color);
    border-bottom: 1px solid var(--app-border-color);
    padding: 10px 0px;
    font-weight: 300;
    font-size: 14px;
    button {
      padding: 15px 25px;
    }
  }
  .set-secret span {
    padding-left: 6px;
  }
  form .buttons {
    display: flex;
    justify-content: space-between;
    grid-gap: 15px;
    padding-bottom: 10px;
  }

`
export default PostQuestionStyled;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/Layout/Sidebar/Sidebar';
import BlackButton from '../../Components/Shared/BlackButton/BlackButton';
import CustomInput from '../../Components/Shared/CustomInput/CustomInput';
import ReportPageStyled from "./ReportPageStyled";


function ReportPage() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        companyName: '',
        phone: '',
        orderNumber: '',
        reportReason: '',
        details: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // const response = await fetch('/api/reports', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(formData)
            // });

            // if (response.ok) {
            console.log(formData.companyName , formData.phone , formData.reportReason , formData.details)
            if (formData.companyName && formData.phone && formData.reportReason && formData.details) {
                navigate('/report/complete');
            } else {
                // alert('신고 접수 중 오류가 발생했습니다.');
                alert('필수값을 모두 입력해주세요.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('서버 통신 중 오류가 발생했습니다.');
        }
    };

    return (
        <ReportPageStyled>
            <Sidebar active="contact" />
            <div>
                <h2>기업 신고</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="companyName">신고 기업명 *</label>
                        <CustomInput
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    {/*<div className="form-group">*/}
                    {/*    <label htmlFor="reporterName">신고자명 *</label>*/}
                    {/*    <CustomInput*/}
                    {/*        name="reporterName"*/}
                    {/*        value={formData.reporterName}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        required*/}
                    {/*    />*/}
                    {/*</div>*/}

                    {/*<div className="form-group">*/}
                    {/*    <label htmlFor="email">이메일 *</label>*/}
                    {/*    <CustomInput*/}
                    {/*        name="email"*/}
                    {/*        type="email"*/}
                    {/*        value={formData.email}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        required*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="form-group">
                        <label htmlFor="phone">연락처 *</label>
                        <CustomInput
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            placeholder="전화번호 혹은 이메일을 입력해주세요."
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="orderNumber">주문번호</label>
                        <CustomInput
                            name="orderNumber"
                            value={formData.orderNumber}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="reportReason">신고 사유 *</label>
                        <textarea
                            name="reportReason"
                            value={formData.reportReason}
                            onChange={handleChange}
                            placeholder="신고 사유를 선택해주세요 (허위광고, 품질 불량, 배송 지연 등)"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="details">상세 내용 *</label>
                        <textarea
                            name="details"
                            value={formData.details}
                            onChange={handleChange}
                            placeholder="구체적인 내용을 작성해주세요."
                            required
                        />
                    </div>

                    <div className="submit">
                        <BlackButton
                            type="submit"
                            title="신고하기"
                            width="50%"
                        />
                    </div>
                </form>
            </div>
        </ReportPageStyled>
    );
}

export default ReportPage;
import React from 'react'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import ReviewsList from '../../Components/CustomerReviews/ReviewsList/ReviewsList'
import CustomerReviewsStyled from './CustomerReviewsStyled'
import PhotoReview from '../../Components/Shared/PhotoReview/PhotoReview'
import Search from '../../Components/Shared/Search/Search'
import Paginator from '../../Components/Shared/Paginator/Paginator'

function CustomerReviews() {
  const reviews = [
    {
      id: 1,
      writer: "네이버 구매평",
      product: "하루7초 해초샐러드 1Box 7봉지",
      rating: 5,
      date: "2022.07.11",
      subject: "굿!........",
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1635832739648_100.jpg"
    },
    {
      id: 1,
      writer: "네이버 구매평",
      product: "하루7초 해초샐러드 1Box 7봉지",
      rating: 5,
      date: "2022.07.11",
      subject: "굿!........",
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1635832739648_100.jpg"
    },
    {
      id: 1,
      writer: "네이버 구매평",
      product: "하루7초 해초샐러드 1Box 7봉지",
      rating: 5,
      date: "2022.07.11",
      subject: "굿!........",
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1635832739648_100.jpg"
    },
    {
      id: 1,
      writer: "네이버 구매평",
      product: "하루7초 해초샐러드 1Box 7봉지",
      rating: 5,
      date: "2022.07.11",
      subject: "굿!........",
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1635832739648_100.jpg"
    },
    {
      id: 1,
      writer: "네이버 구매평",
      product: "하루7초 해초샐러드 1Box 7봉지",
      rating: 5,
      date: "2022.07.11",
      subject: "굿!........",
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1635832739648_100.jpg"
    }
  ]
  return (
    <CustomerReviewsStyled>
      <Sidebar />
        <PhotoReview />
        <ReviewsList reviews={reviews} />
        <Search pencilTo="" />
        <Paginator numPages={6} />
    </CustomerReviewsStyled>
  )
}

export default CustomerReviews
import React, { useEffect, useState } from "react";
import { FaShareAlt } from "react-icons/fa";
import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
import ThumbnailSlider from "../../Components/Shared/ProductThumbnailSlider/ThumbnailSlider";
import Rating from "../../Components/Shared/Rating/Rating";
import ProductDetailStyled from "./ProductDetailStyled";
import Naver from "../../Assets/naver.png";
import RelatedProducts from "../../Components/ProductDetail/RelatedProducts/RelatedProducts";
import ReviewList from "../../Components/Shared/ReviewList/ReviewList";
import Share from "../../Components/ProductDetail/Share/Share";
import PostReviewForm from "../../Components/ProductDetail/PostReviewForm/PostReviewForm";
import BlackButton from "../../Components/Shared/BlackButton/BlackButton";
import CartTable from "../../Components/Shared/CartTable/CartTable";
import { constants } from "../../constants";
import axios from "axios";
import axiosInstance from '../../Api/axiosInstance';
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { getImageUrlFromTag } from "../../Utils/getImgUrl";

function ProductDetail() {
  // const serviceURI =
  //   constants.baseURI + constants.productService + "detail/product?productid=";
  const serviceURI =
    constants.productService + "detail/product?productid=";
  const reviewsServiceUrl =
    // constants.baseURI +
    constants.reviewService +
    "/product/reviews?productid=";
  const prams = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [showShare, setShowShare] = useState(false);
  const reduceQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const increaseQuantity = () => setQuantity(quantity + 1);
  const onChangeQuantity = (e) => setQuantity(e.target.value);

  // axios.defaults.withCredentials = true;

  const exProduct = {
    id: 1,
    name: "하루7초 해초샐러드 1Box 7봉지",
    thumbnail:
      "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1639363734961_1000.png",
    status: "NEW",
    discountPrice: 12800,
    actualPrice: 16000,
    category: "달하루 양갱",
    images: [
      "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1639363734961_1000.png",
      "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1639363742443_1000.png",
      "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1639363749222_1000.png",
      "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1639363752742_1000.png",
      "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1639363756571_1000.jpg",
    ],
    reviews: [
      {
        id: 1,
        title: "네이버 구매평",
        date: "2022.03.24",
        message:
          "양갱 맛이 정말 꿀맛이에요.좋은 제품 잘 만들어서 주셔서 정말 고마워요.오래오래 먹고 싶네요.",
        image:
          "https://phinf.pstatic.net/checkout.phinf/20211215_269/1639554681622sdJ8v_JPEG/review-attachment-a2d9c867-80a6-4fe1-8f86-479479bc8bbe.jpeg",
      },
    ],
    rating: 5,
    price: 100,
    relatedProducts: [
      {
        id: 1,
        name: "하루7초 해초샐러드 1Box 7봉지",
        thumbnail:
          "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1639363734961_500.png",
        status: "NEW",
        discountPrice: 12800,
        actualPrice: 16000,
        category: "달하루 양갱",
      },
      {
        id: 2,
        name: "달하루 양갱 세트 14개입",
        thumbnail:
          "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1651626375881_500.jpg",
        status: "NEW",
        discountPrice: 28000,
        actualPrice: 35000,
        category: "달하루 양갱",
      },
      {
        id: 3,
        name: "달하루 양갱 세트 5개입",
        thumbnail:
          "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1651626124123_500.jpg",
        status: "NEW",
        discountPrice: 12000,
        actualPrice: 15000,
        category: "달하루 양갱",
      },
      {
        id: 4,
        name: "[자누담] 100% 생분해 친환경 식물성 봉투 - 드로우스트링 50장",
        thumbnail:
          "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1637891614925_500.jpg",
        status: "NEW",
        discountPrice: 32000,
        actualPrice: 40000,
        category: "자누담",
      },
    ],
  };

  const fetchProduct = async () => {
    let product;
    const response = await axiosInstance.get(serviceURI + prams.id);
    product = response.data;
    try {
      const reviews = await axiosInstance.get(reviewsServiceUrl + prams.id);
      product.reviews = reviews.data;
      product.rating =
        product.reviews
          .map((review) => review.rating)
          .reduce((a, b) => a + b, 0) / product.reviews.length;
    } catch (error) {
      product.reviews = [];
      product.rating = 0;
    }
    setProduct(product);
  };

  const openNewTab = (url) => {
    window.open(constants.smartstoreURI + url, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    fetchProduct();
  }, [product.length]);

  return (
    <ProductDetailStyled>
      <Sidebar />
      <ThumbnailSlider
        images={[
          getImageUrlFromTag(product.image) || product.product_image,
          getImageUrlFromTag(product.image) || product.product_image,
          getImageUrlFromTag(product.image) || product.product_image,
          getImageUrlFromTag(product.image) || product.product_image,
        ]}
      />
      <h2>{product.name || product.product_name}</h2>
      {/* <div style={{ marginTop: "5px" }}>
        <Rating rating={product?.rating || 0} />({product?.reviews?.length || 0}{" "}
        {product?.reviews?.length === 1 ? (
          <span>review</span>
        ) : (
          <span>reviews</span>
        )}
        )
      </div> */}
      <div className="product-detail prices">
        <span className="actual-price">
          {product.price || product.product_price}원
        </span>
        {/* <span className="discount-price">
          {product.price || product.product_price}원
        </span> */}
      </div>

      {/* <div className="delivery-fee">
        <p className="title">배송비</p>
        <p className="content">
          3,000원 (30,000원 이상 구매 시 무료) 제주 및 도서 산간 2,500원 추가
        </p>
      </div> */}
      {/* <div className="quantity">
        <p>수량</p>
        <CartTable
          reduceQuantity={reduceQuantity}
          onChangeQuantity={onChangeQuantity}
          increaseQuantity={increaseQuantity}
        />
      </div> */}

      {/* <div className="order">
        <div className="order-quantity">
          <span>주문 수량</span>
          <span>{quantity}개</span>
        </div>
        <div className="product-amount">
          <span>총 상품 금액</span>
          <span>{quantity * (product.price || product.product_price)} 원</span>
        </div>
      </div> */}
      <div className="buttons">
        <button
          className="purchase"
          onClick={(e) => {
            openNewTab(product.smartstoreNum || product.smartstore_num);
          }}
        >
          구매하기
        </button>
        <button
          className="add-to-cart"
          onClick={(e) => {
            // openNewTab(constants.smartstoreURI + product.smartstore_num);
            navigate("/under_construction");
          }}
        >
          장바구니에 담기
        </button>
      </div>
      <div className="naver">
        <a href="https://m.pay.naver.com/mobile/about">
          <img src={Naver} alt="Pay with naver" />
        </a>
      </div>

      <div className="related-products">
        {/* 연관된 상품 데이터 추가 필요 */}
        {/* <RelatedProducts products={product.relatedProducts} /> */}
      </div>
      <div
        className="bottom"
        dangerouslySetInnerHTML={{
          __html: product.description || product.product_description,
        }}
      ></div>
      {/* <div className="share">
        <button onClick={() => setShowShare(!showShare)}>
          <FaShareAlt
            style={{
              fontFamily: "var(--app-font-family)",
              fontWeight: "300",
            }}
            fillOpacity={0.8}
            size={15}
            fontVariant={1}
          />{" "}
          Share
        </button>
        {showShare && <Share />}
      </div> */}
      {showReviewForm && <PostReviewForm />}
      <div className="reviews">
        <div>
          <div>
            {/* 데이터 리뷰 추가 필요 */}
            <p>{Number(product?.rating).toFixed(1) || 0} / 5</p>{" "}
            <span>({product?.reviews?.length || 0} reviews)</span>
          </div>
          {/*<div className="photo-review">*/}
          {/*  <input type="checkbox" id="photo-review" />*/}
          {/*  <label htmlFor="photo-review">Photo Review</label>*/}
          {/*</div>*/}
        </div>
        <ReviewList reviews={product?.reviews || []} />
      </div>
      <div className="post-review">
        {!showReviewForm && (
          <BlackButton
            title="리뷰 쓰기"
            width="fit-content"
            onClick={() => setShowReviewForm(true)}
          />
        )}
      </div>
    </ProductDetailStyled>
  );
}

export default ProductDetail;

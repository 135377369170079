import React, { useContext } from "react";
import HeaderStyled from "./HeaderStyled";
import Logo from "../../../Assets/logo.png";
import { BiShoppingBag, BiSearch } from "react-icons/bi";
import SidebarContext from "../../../Context/SidebarContext";
import HeaderContext from "../../../Context/HeaderContext";
import SearchComponent from "../SearchComponent/SearchComponent";
import { useNavigate } from "react-router-dom";

function Header() {
  const { state: sidebarState, dispatch: sidebarDispatch } =
    useContext(SidebarContext);
  const { state: headerState, dispatch: headerDispatch } =
    useContext(HeaderContext);
  const navigate = useNavigate();
  const { showSidebar } = sidebarState;
  const toggleSidebar = () => {
    if (sidebarState.showSidebar) {
      sidebarDispatch({ type: "HIDE_SIDEBAR" });
    } else {
      sidebarDispatch({ type: "SHOW_SIDEBAR" });
    }
  };
  const showSearchBar = () => {
    headerDispatch({ type: "HIDE_HEADER" });
  };
  const hideSearchBar = () => {
    headerDispatch({ type: "SHOW_HEADER" });
  };

  return (
      <HeaderStyled>
        <div>
          <div className="nav-toggler" onClick={toggleSidebar}>
            <span></span>
          </div>
        </div>
        <span>
          <img className="logo" style={{ width: "120px" }} src={Logo} alt="Logo" onClick={() => navigate('/')}/>
        </span>
        <div className="icons">
          <BiShoppingBag
              size={25}
              style={{ marginRight: "10px" }}
              color={showSidebar ? "white" : "rgba(0,0,0,.6)"}
              fontWeight={"1000"}
              onClick={() => navigate("/under_construction")}
          />
        </div>
      </HeaderStyled>
  );
  // return (
  //   <HeaderStyled>
  //     {headerState.showHeader ? (
  //       <>
  //         <div>
  //           <div className="nav-toggler" onClick={toggleSidebar}>
  //             <span></span>
  //           </div>
  //         </div>
  //         <div>
  //           <img style={{ width: "120px" }} src={Logo} alt="Logo" />
  //         </div>
  //         <div className="icons">
  //           <BiSearch
  //             size={25}
  //             style={{ marginRight: "10px" }}
  //             color={"rgba(0,0,0,.6)"}
  //             fontWeight={"1000"}
  //             onClick={() => showSearchBar()}
  //           />
  //           <BiShoppingBag
  //             size={25}
  //             style={{ marginRight: "10px" }}
  //             color={showSidebar ? "white" : "rgba(0,0,0,.6)"}
  //             fontWeight={"1000"}
  //             onClick={() => navigate("/under_construction")}
  //           />
  //         </div>
  //       </>
  //     ) : (
  //       <SearchComponent onHide={hideSearchBar} />
  //     )}
  //   </HeaderStyled>
  // );
}

export default Header;

import React from 'react';
import RadioButton from './RadioButton';
import RadioButtonStyled from './RadioButtonStyled';

const RadioGroup = ({ options, selectedValue, onChange }) => {
  return (
    <RadioButtonStyled>
      {options.map((option) => (
        <RadioButton
          key={option.value}
          label={option.label}
          value={option.value}
          checked={selectedValue === option.value}
          onChange={onChange}
        />
      ))}
    </RadioButtonStyled>
  );
};

export default RadioGroup;

import styled from "styled-components";
const MainPageStyled = styled.div `
  div.marine-desc {
    padding: 0px 20px;
    margin-bottom: 100px;
  }
  div.marine-desc p {
    text-align: center;
    color: #241801;
    line-height: 35px;
  }
`
export default MainPageStyled;
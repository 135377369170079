import React, { useState } from 'react'
import ProductScrollList from '../ProductScrollList/ProductScrollList'
import ProductList from '../ProductList/ProductList'
import ProductsSectionStyled from './ProductsSectionStyled'

// function ProductsSection({title: strTitle, products: all, valueButtons: myValueButtons, productButtons: myPproductButtons, scroll: isScroll
function ProductsSection({title: strTitle, products: all, scroll: isScroll
}) {
  // const [filtered, setFiltered] = useState(all);
  // const [title, setTitle] = useState(strTitle);

  isScroll = isScroll === undefined ? false : isScroll;
  
  return (
    <ProductsSectionStyled>
      <h3>{strTitle}</h3>
      
      {/* 필터 */}
      {/* <FilterSection
        valueButtons={valueButtons}
        onClickValue={onClickValue}
        productButtons={productButtons}
        onClickProduct={onClickProduct}
        onFilterButtonClick={handleFilterButtonClick}
      /> */}


      {isScroll === false ? (
        <ProductList products={all} />
      ): 
      (
        <ProductScrollList products={all} />
      )}
    </ProductsSectionStyled>
  )
}

export default ProductsSection
import React from 'react'
import { FaCamera } from 'react-icons/fa'
import BlackButton from '../../Shared/BlackButton/BlackButton'
import PostReviewFormStyled from './PostReviewFormStyled'

function PostReviewForm() {
  return (
    <PostReviewFormStyled>
      <p className='title'>review</p>
      <div className='login-div'>
        <p>로그인이 필요한 기능입니다.</p>
        <div className="form">
          <div className="image">
            <label for="image">
              <FaCamera color='#fff' />
            </label>
            <input type="file" name="image" id="image" />
          </div>
          {/*<div className="choose">*/}
          {/*  <select name="" id="">*/}
          {/*    <option>Choose</option>*/}
          {/*  </select>*/}
          {/*</div>*/}
          <div>
            <select name="rating" id="rating">
              <option>Rating</option>
            </select>
            <BlackButton title="등록하기" />
          </div>
        </div>
      </div>
    </PostReviewFormStyled>
  )
}

export default PostReviewForm
import React from 'react'
import Slider from '../Slider/Slider'

function Overview({homeSlider, data}) {
  return (
    <div>
      <Slider homeSlider={homeSlider} data={data} />
    </div>
  )
}

export default Overview
import React from 'react'
import BlackButton from '../BlackButton/BlackButton'
import CustomTextAreaStyled from './CustomTextAreaStyled'

function CustomTextArea() {
  return (
    <CustomTextAreaStyled>
      <textarea></textarea>
      <div>
        <BlackButton title="COMMENT" width="fit-content" onClick={()=>console.log('clicked')} />
      </div>      
    </CustomTextAreaStyled>
  )
}

export default CustomTextArea
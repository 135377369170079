const constants = {
  // baseURI = "http://localhost:9999/",
  // in package.json "proxy": "http://18.180.130.38:8080"

  // baseURI = "https://firstlb-1313700850.ap-northeast-1.elb.amazonaws.com/zuulwar/",
  // aws amplify only supporting https, never http
  // ssl https available through load balancer
  // load balancer https://firstlb-1313700850.ap-northeast-1.elb.amazonaws.com/zuulwar/product/productwar/products

  // http://18.180.130.38:8080/zuulwar가 localhost:3000이 되었다고 상상 (proxy)
  // zuul local에서는 http://localhost:9999/product/products

  // in package.json "proxy": "https://xn--o39a535aouf0sj.xn--h32bi4v.xn--3e0b707e"
  // baseURI = "/zuulwar/",
  // https://xn--o39a535aouf0sj.xn--h32bi4v.xn--3e0b707e/zuulwar/product/productwar/products

  // http://18.180.130.38:8080/zuulwar/product/productwar/products
  // http://18.180.130.38:8080/zuulwar/product/products // not working well

  // baseURI = "https://xn--o39a535aouf0sj.xn--h32bi4v.xn--3e0b707e/zuulwar/",

  // baseURI = "https://xn--o39a535aouf0sj.xn--h32bi4v.xn--3e0b707e/zuulwar/",
  // 지금 company 자체가 서비스 이름인데 url에도 company가 들어가있어서 그런 거 같은데
  // kindNews랑 ucc도 마찬가지로 zuul을 거쳐서는 안 나와

  // 지금 서비스들 다 한군데 몰아넣어서, 이 한 url로 각기 직접 접근 가능
  // 빠른 임시 처방 // zuul 없이 개별 서비스들 접근
  // baseURI : "https://xn--o39a535aouf0sj.xn--h32bi4v.xn--3e0b707e/",
  // baseURI : "http://3.36.38.224:8080/",
  baseURI: "https://xn--2-2x5e488bhkh79k.xn--hk3b17f.xn--3e0b707e/zuulwar/", // zuul
  // baseURI : "http://localhost:9999/",

  authService: "auth/authwar/",
  // authService: "auth/authwar/",

  customerService: "customer/customerwar/",
  // customerService: "customer/customerwar/",

  qnaService: "qna/qnawar/",
  // qnaService: "qna/qnawar/",

  reviewService: "review/reviewwar",
  // reviewService: "review/",

  productService : "product/productwar/",
  // productService: "product/",
  // productService : "",
  // productService : "productwar/", // no zuul

  kindNewsService : "kindnews/kindnewswar/",
  // kindNewsService: "kindnews/",
  // kindNewsService : "kindNewswar/", // no zuul

  companyService : "company/companywar/",
  // companyService: "company/", // localhost
  // companyService : "companywar/", // no zuul

  uccService : "ucc/uccwar/",
  // uccService: "ucc/",
  // uccService : "uccwar/", // no zuul

  smartstoreURI: "https://smartstore.naver.com/plusgood/products/",
};

const productfilterkeywords = [
  // { text: "All", index: 0 },
  { text: "화장품", index: 1 },
  { text: "생활용품", index: 2 },
  { text: "주방용품", index: 3 },
];

const valuefilterkeywords = [
  // { text: "All", index: 0 },
  { text: "장애인 채용", index: 1 },
  { text: "친환경", index: 2 },
];

export { constants, productfilterkeywords, valuefilterkeywords };

import styled from "styled-components";
const BottomBarStyled = styled.div `
  background-image: url("https://thumb.sixshop.kr/uploadedFiles/186517/default/image_1636531449999.jpg?width=2500");
  height: 300px;
  width: 100%;
  background-position: bottom;
  background-size: cover;
  margin: 80px 0px;
  .overlay {
    padding: 70px 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    background-color: rgba(191,173,140,.4);
  }
  button {
    background-color: #fff;
    color: var(--app-black);
    padding: 12px 20px;
    border-radius: 20px;
    border: none;
  }
  h2 {
    font-family: "Nanum Gothic", sans-serif;
    font-size: 1.5em;
    line-height: 1.5em;
    color: #fff;
  }
`

export default BottomBarStyled;
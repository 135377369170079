// EventDetailPage.js
import {useLocation, useParams} from 'react-router-dom';
import {
    DetailPageStyled,
    ImageSection,
    ContentSection,
    TagsSection
} from './EventDetailPageStyled';

const EventDetailPage = () => {
    const { id } = useParams();
    const location = useLocation();
    const company = location.state?.company;

    if (!company) {
        return <div>Event not found</div>;
    }

    const tags = company.tags.split('#').filter(tag => tag.trim());

    return (
        <DetailPageStyled>
            <ImageSection>
                <img src={company.media_url} alt={company.value} />
            </ImageSection>

            <ContentSection>
                <TagsSection>
                    {tags.map((tag, index) => (
                        <span key={index}>#{tag.trim()}</span>
                    ))}
                </TagsSection>
                <h1>{company.value}</h1>
                <div className="caption">{company.caption}</div>
            </ContentSection>
        </DetailPageStyled>
    );
};

export default EventDetailPage;
import styled from "styled-components";
const SliderStyled = styled.div `
  height: ${props => (props.homeSlider ? `441px` : `279px`)};;
    //height: 100%;
    width: 100%;
  position: relative;
  
  .slide {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity ease .5s;
  }
  .slide.animated {
    opacity: 1;
  }
  .slide-buttons {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 10px;
    display: flex;
    justify-content: center;
  }
`

export default SliderStyled;
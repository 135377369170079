import React, {useState, useEffect} from 'react'
import SlideBtn from './SlideBtn/SlideBtn';
import SliderStyled from './SliderStyed'

function Slider({homeSlider, data}) {
  const [slideIndex, setSlideIndex] = useState(0);
  const [xDown, setXDown] = useState(null);
  const [lastSwipe, setLastSwipe] = useState(null);
  
  const [timer, setTimer] = useState(null);

  // let timer = null;

  const sliderFunc = () => {
    let len = data.length;  
    setSlideIndex((prev)=>{
      if(prev == len-1) {
        return 0;
      }else{
        return prev + 1;
      }
    })
  }

  const changeSlideIndex = (index) => {
    setSlideIndex(index)
    clearInterval(timer);
    setTimer(setInterval(sliderFunc, 5000));
  }

  useEffect(()=>{
    setTimer(setInterval(sliderFunc, 5000));
  }, [])

  const touchStart = (e) => {
    const firstTouch = e.touches[0];
    setXDown(firstTouch.clientX);
  }

  const touchMove = (e) => {
    var xUp = e.touches[0].clientX;
    var xDiff = xDown - xUp;

    if(Math.abs(xDiff) > 0) {
      if(xDiff > 0) {
        setLastSwipe('left swipe');
      }else{
        setLastSwipe('right swipe')
      }
    }
  }
  const touchEnd = (e) => {
    if(lastSwipe == 'right swipe') {
      let len = data.length;
      setSlideIndex((prev)=>{
        if(prev == 0) {
          return len-1;
        }else{
          return prev - 1;
        }
      })
    }else{
      let len = data.length;
      setSlideIndex((prev)=>{
        if(prev == len-1) {
          return 0;
        }else{
          return prev + 1;
        }
      })
    }
    setLastSwipe(null);
    clearInterval(timer);
    setTimer(setInterval(sliderFunc, 5000));
  }
  return (
    <SliderStyled homeSlider={homeSlider}>
      {
        data.map((img, index)=>{
          return <div style={
            {
              backgroundImage: `url(${img})`, 
              backgroundPosition: 'center', 
              backgroundOrigin: 'center',
              backgroundSize: 'cover',
              position: 'absolute',
              backgroundRepeat: 'no-repeat',
            }} className={slideIndex == index ? 'slide animated':'slide'} 
            key={index}
            onTouchStart={(e)=>touchStart(e)}
            onTouchMove={(e)=>touchMove(e)}
            onTouchEnd={(e)=>touchEnd(e)}
            >
            
          </div>
        })
      }
      {
        data.length > 1 && 
        <div className="slide-buttons">
        {
          data.map((img, index)=>{
            return <SlideBtn 
              active={slideIndex == index ? true:false} 
              onClick={()=>changeSlideIndex(index)} 
              key={index}  
            />
          })
        }
      </div>
      }
    </SliderStyled>
  )
}

export default Slider
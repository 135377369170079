import "./App.css";
import { useState } from 'react';
import { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Layout/Header/Header";
import MainPage from "./Pages/MainPage/MainPage";
import Footer from "./Components/Layout/Footer/Footer";
import { SidebarProvider } from "./Context/SidebarContext";
import AboutPage from "./Pages/AboutPage/AboutPage";
import MarineStory from "./Pages/MarineStory/MarineStory";
import SecondsADay from "./Pages/7SecondsADay/7SecondsADay";
import EventPage from "./Pages/EventPage/EventPage";
import EventDetailPage from "./Pages/EventDetailPage/EventDetailPage";
import ProductDetail from "./Pages/ProductDetailPage/ProductDetail";
import CustomerReviews from "./Pages/CustomerReviews/CustomerReviews";
import ReviewDetail from "./Pages/ReviewDetail/ReviewDetail";
import Questions from "./Pages/Questions/Questions";
import QuestionDetail from "./Pages/QuestionDetail/QuestionDetail";
import LoginPage from "./Pages/LoginPage/LoginPage";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import SignupPage from "./Pages/SignupPage/SignupPage";
import { HeaderProvider } from "./Context/HeaderContext";
import PostQuestion from "./Pages/PostQuestion/PostQuestion";
import CartPage from "./Pages/CartPage/CartPage";
import ProductsPage from "./Pages/ProductsPage/ProductsPage";
import CompanyPage from "./Pages/CompanyPage/CompanyPage";
import CompanyDetail from "./Pages/CompanyDetailPage/CompanyDetail";
import NotYetBuilt from "./Pages/NotYetBuilt/NotYetBuilt";
import Cookies from 'js-cookie';
import EntryPage from "./Pages/Entry/EntryPage";
import EntryCompletePage from "./Pages/Entry/EntryCompletePage";
import ReportCompletePage from "./Pages/Report/ReportCompletePage";
import ReportPage from "./Pages/Report/ReportPage";  // js-cookie 라이브러리 import 추가
import MaxWidthLayout from './MaxWidthLayout'; // 가로 길이 최대값 600px로 조절

function App() {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  useEffect(() => {
    // "authToken"이라는 이름의 쿠키를 확인
    const token = Cookies.get('refresh-token');

    console.log("token : " + token);
    
    if (token) {
      setIsLoggedIn(true);  // 쿠키가 존재하면 로그인 상태로 변경
    } else {
      setIsLoggedIn(false);  // 쿠키가 없으면 로그아웃 상태로 설정
    }
  }, []);

  return (
    <MaxWidthLayout>
    <SidebarProvider>
      <HeaderProvider>
        <Router>
          <div className="App">
            <Header />
            <div className="main-container">
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/about/marine-story" element={<MarineStory />} />
                <Route path="/products" element={<ProductsPage />} />
                <Route path="/products/:menu/:tag" element={<ProductsPage />} />
                <Route path="/companys" element={<CompanyPage />} />
                <Route
                  path="/companys/detail/:id"
                  element={<CompanyDetail />}
                />
                <Route path="/entry" element={<EntryPage />} />
                <Route path="/entry/complete" element={<EntryCompletePage />} />
                <Route path="/report" element={<ReportPage />} />
                <Route path="/report/complete" element={<ReportCompletePage />} />
                <Route path="/7seconds-a-day" element={<SecondsADay />} />
                <Route path="/event" element={<EventPage />} />
                <Route path="/event/:id" element={<EventDetailPage />} />
                <Route path="/reviews" element={<CustomerReviews />} />
                <Route path="/reviews/:id" element={<ReviewDetail />} />
                <Route path="/product/:id" element={<ProductDetail />} />
                <Route path="/questions" element={<Questions />} />
                <Route path="/questions/:id" element={<QuestionDetail />} />
                <Route path="/post-question" element={<PostQuestion />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/cart" element={<CartPage />} />
                <Route path="/under_construction" element={<NotYetBuilt />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </HeaderProvider>
    </SidebarProvider>
    </MaxWidthLayout>
  );
}

export default App;

import styled from "styled-components";
const MarineWhiteComponentStyled = styled.div `
  padding: 20px;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 85%;
  }
  h2 {
    font-size: 1.4em;
    padding: 30px 0px 50px 0px;
  }
`

export default MarineWhiteComponentStyled;
export class CustomerDTO  {
  // constructor(userId, password, email, customerRank, dateSignedup, dateLastused, phonenumber, nickname, marketingflag, profileimage, address){
  constructor(email, password, name, phonenumber, marketingflag, address){
  //  this.userId = userId;
   this.password = password;
   this.email = email;
   this.name = name;
  //  this.customerRank = customerRank;
  //  this.dateSignedup = dateSignedup;
  //  this.dateLastused =  dateLastused;
   this.phonenumber = phonenumber;
  //  this.nickname = nickname;
   this.marketingflag = marketingflag;
  //  this.profileimage = profileimage;
   this.address = address;
  }
}
import React from 'react'
import MarineInnovationStyled from './MarineInnovationStyled'

function MarineInnovation() {
  return (
    <MarineInnovationStyled>
      <div>
        <img 
          src="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1635813508589_750.png" 
          alt="Water" />
      </div>
      <div>
        <h2>Marine Innovation</h2>
        <p>
          지구의 70%가 바다로 구성되어 있으며, 자연의 모든 것들이 마지막으로 흘러가서 모이는 곳 또한 바다입니다.
        </p>
        <p>
          우리가 마시는 공기 속에도 바다의 생명이 담겨져 있으며, 모든 자연의 처음이자 마지막 종착점은 바다입니다.  
        </p>
        <p>
          마린과 이노베이션을 합친 '마린이노베이션'이라는 이름에는 바다 자원인 해조류로 환경문제 해결에
        </p>
        <p>
          '혁신'을 가져오겠다는 의미가 담겨있습니다.
        </p>
      </div>
      <div>
        <h2>바다, 그 영원한 생명 </h2>
        <p>
          마린이노베이션은 다음세대에게 깨끗한 지구를 물려주기 위해 설립되었습니다. 
        </p>
        <p>
          제주 우뭇가사리에서 추출한 한천으로 식품인 달하루 양갱을 만들며, 해조류의 약 30% 정도는 해초샐러드를 만들고, 
        </p>
        <p>
          나머지 먹지 못하는 70%의 부산물을 통해 생분해 식품용기 '자누담'을 제작합니다.
        </p>
      </div>
      <div>
        <img src="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1635814047281_750.png" alt="Green plants" />
      </div>
    </MarineInnovationStyled>
  )
}

export default MarineInnovation
import React from 'react'
import MarineInnovationStyled from './MarineInnovationStyled'

function MarineInnovation() {
  return (
    <MarineInnovationStyled>
      <h2>Marine Innovation Information</h2>
      <div className="address">
        <h3>Address</h3>
        <p>135, Cheoyong Industrial 3-gil, Ulju-gun, Ulsan, 2nd floor, Gyeonggong 2-dong, Ulsan.</p>
      </div>
      <div className="contact">
        <h3>Contact</h3>
        <p>070-7771-5757</p>
      </div>
      <div className="work">
        <h3>Work</h3>
        <p>08:30AM ~ 17:30PM</p>
      </div>
      <img src="https://contents.sixshop.com/thumbnails/uploadedFiles/186517/default/image_1636685914876_750.jpg" alt="water Img" />
    </MarineInnovationStyled>
  )
}

export default MarineInnovation
import styled from "styled-components";
const MarineInnovationStyled = styled.div `
  font-family: var(--app-font-family);
  color: var(--app-black);
  line-height: 35px;
  img {
    width: 80%;
    margin-bottom: 20px;
  }
  h2 {    
    font-size: 25px;
    padding: 30px 0px;
  }
  h3 {
    font-size: 18px;
    padding: 25px 0px 0px 0px;
  }
  .work {
    margin-bottom: 25px;
  }
`

export default MarineInnovationStyled;
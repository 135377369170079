import React from 'react'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import EventCard from '../../Components/Shared/EventCard/EventCard'
import EventPageStyled from './EventPageStyled'

function EventPage() {
  // const events = [
  //   {
  //     id: 9,
  //     value: "봉사단체 후원",
  //     media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/9volunteer.png",
  //     caption: "알고 계셨나요?\n전국 1만 2천여 개의 봉사단체,\n매일 35만 명의 자원봉사자들이\n우리 주변을 따뜻하게 만들고 있다는 사실을.\n\n🤝 가치살래에 있는 가치발견 #1\n가치살래에는 브랜드의 제품으로 \n봉사단체를 후원하는 착한 기업들이 있습니다.",
  //     tags: "#가치살래 #착한소비 #봉사단체후원 #나눔실천 #지역사회공헌 #자원봉사 #따뜻한동행 #volunteer #이웃사랑 #함께하는가치"
  //   },
  //   {
  //     id: 8,
  //     value: "유니세프 후원",
  //     media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/8unicef.png",
  //     caption: "🌏 전 세계 아이들의 동반자, 유니세프\n가치살래가 소개하는 오늘의 가치입니다.\n\n매일 14,000명의 어린이가 예방 가능한 질병으로 생명을 잃습니다",
  //     tags: "#가치살래 #착한소비 #유니세프 #세계시민 #아동인권 #글로벌나눔 #지속가능발전 #sdgs #세상을바꾸는소비 #나눔으로번영"
  //   },
  //   {
  //     id: 7,
  //     value: "여성 기업",
  //     media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/7womancompany.png",
  //     caption: "전체 창업자 중 여성 비율 39.8% \n실제 운영 5년 이상 생존율 24.2%",
  //     tags: "#가치살래 #착한소비 #여성기업 #우먼파워 #여성창업 #sheowned #기업다양성 #성평등 #경제적자립 #임팩트소비"
  //   },
  //   {
  //     id: 6,
  //     value: "소년/소녀 가장 후원",
  //     media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/6earlymost.png",
  //     caption: "대한민국 소년/소녀 가장 1만 4천여 명\n\n평균 연령 15.7세, 학업과 생계, 미래의 꿈 사이에서 고민하는 청소년들이 있습니다",
  //     tags: "#가치살래 #착한소비 #소년소녀가장 #청소년후원 #교육지원 #꿈지원 #미래세대 #희망나눔 #청소년복지 #함께자라기"
  //   },
  //   {
  //     id: 5,
  //     value: "독거노인 후원",
  //     media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/5oldPerson.png",
  //     caption: "가치 살래에서 만나는 따뜻한 동행: 독거노인 후원 👵👴\n\n매일 7명의 어르신이 홀로 외롭게 생을 마감하고 계세요.",
  //     tags: "#가치살래 #착한소비 #독거노인후원 #노인복지 #세대공감 #이웃돌봄 #공동체 #실버케어 #효실천 #따뜻한동행"
  //   },
  //   {
  //     id: 4,
  //     value: "공정무역",
  //     media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/4fairtrade.png",
  //     caption: "가치살래에서 만날 수 있는 착한 가치 톺아보기: 공정무역!\n\n전 세계 커피 농가의 80%가 빈곤선 이하의 삶을 살고 있어요.",
  //     tags: "#가치살래 #착한소비 #공정무역 #윤리적소비 #지속가능무역 #상생소비 #fairtrade #생산자권리 #책임소비 #윤리적커피"
  //   },
  //   {
  //     id: 3,
  //     value: "친환경 제품",
  //     media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/3%08ecofrendly.png",
  //     caption: "우리나라 연간 플라스틱 사용량 98.2kg/1인\nOECD 국가 중 두 번째로 높은 수준이에요.",
  //     tags: "#가치살래 #착한소비 #친환경 #제로웨이스트 #지구를위한선택 #그린라이프 #친환경소비 #sustainable #플라스틱프리 #업사이클링"
  //   },
  //   {
  //     id: 2,
  //     value: "장애인 채용 기업",
  //     media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/2disabledperson.png",
  //     caption: "\"진정한 포용은 기회의 평등에서 시작됩니다\"\n\n2023년 장애인 고용률 3.1% 의무고용률 3.1%는 달성했지만,",
  //     tags: "#가치살래 #착한소비 #장애인채용 #다양성 #포용적기업 #함께일하기 #장애인고용 #배리어프리 #평등한일터 #더나은일터"
  //   },
  //   {
  //     id: 1,
  //     value: "유기견 후원",
  //     media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/1dog.png",
  //     caption: "가치살래 오늘의 가치: 유기견 후원 🐕\n\n\"한 해 7만 마리. 새로운 가족을 기다리는 반려동물들의 이야기\"",
  //     tags: "#가치살래 #착한소비 #퓨어필드 #착한화장품 #비건뷰티 #동물실험반대 #유기견 #유기견보호소 #브랜드A #브랜드B"
  //   }
  //
  // ]

  const events = [
    {
      id: 9,
      value: "봉사단체 후원",
      media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/9volunteer.png",
      caption: "알고 계셨나요?\n전국 1만 2천여 개의 봉사단체,\n매일 35만 명의 자원봉사자들이\n우리 주변을 따뜻하게 만들고 있다는 사실을.\n\n🤝 가치살래에 있는 가치발견 #1\n가치살래에는 브랜드의 제품으로 \n봉사단체를 후원하는 착한 기업들이 있습니다.\n\n이렇게 도울 수 있어요!\n가치살래가 엄선한 봉사단체 후원 브랜드\n\n🏃‍♀️ [브랜드 A]\n달리기 앱 수익금으로\n소외지역 놀이터 만들기\n\n🍜 [브랜드 B]\n매월 마지막 주 수요일\n노숙인 무료급식 지원\n\n가치살래 검색 필터에서 '봉사단체 후원'을 적용하고\n변화에 동참해보세요!\n\n💝 우리가 함께하면\n일상이 봉사가 됩니다.",
      tags: "#가치살래 #착한소비 #봉사단체후원 #나눔실천 #지역사회공헌 #자원봉사 #따뜻한동행 #volunteer #이웃사랑 #함께하는가치"
    },
    {
      id: 8,
      value: "유니세프 후원",
      media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/8unicef.png",
      caption: "🌏 전 세계 아이들의 동반자, 유니세프\n가치살래가 소개하는 오늘의 가치입니다.\n\n매일 14,000명의 어린이가 예방 가능한 질병으로 생명을 잃습니다\n2억 명의 아이들이 교육받지 못하고 있어요\n10억 명의 어린이들이 깨끗한 물을 마시지 못해요\n\n💙유니세프 후원으로 세상을 바꾸는데 기여하는 착한 브랜드를 소개합니다\n[브랜드 A] \"한 번의 구매로 두 번의 행복을\"\n\n구매액 15%가 유니세프 백신 지원에\n이미 1만 명의 아이들에게 백신을 전달했어요\n\n[브랜드 B] \"배움이라는 선물\"\n\n판매 수익으로 개발도상국 학교 건립 지원\n현재까지 3개국 5개 학교 완공\n\n당신의 일상적인 소비가\n전 세계 아이들의 미래를 바꿀 수 있어요.\n가치살래에서 유니세프 후원 브랜드를 만나보세요.",
      tags: "#가치살래 #착한소비 #유니세프 #세계시민 #아동인권 #글로벌나눔 #지속가능발전 #sdgs #세상을바꾸는소비 #나눔으로번영"
    },
    {
      id: 7,
      value: "여성 기업",
      media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/7womancompany.png",
      caption: "전체 창업자 중 여성 비율 39.8% \n실제 운영 5년 이상 생존율 24.2%\n\n여성창업자 10명 중 7명이 사업 초기에 성별로 인한 어려움을 겪었다고 해요.\n자금조달의 한계, 네트워크 부족, 일-가정 양립 등\n아직도 풀어야 할 숙제들이 많습니다.\n하지만 변화는 이미 시작되었어요!\n\n오늘 가치살래가 소개하는 가치: 여성기업 👩‍💼\n가치살래가 주목하는 여성기업들을 소개합니다.\n\n✨ [브랜드 A]\n\"29살에 시작한 작은 도전이 10명 규모 기업이 되기까지\"\n친환경 생활용품으로 새로운 라이프스타일 제안\n\n💪 [브랜드 B]\n경력단절 여성들과 함께 성장하는 패션 브랜드\n디자이너부터 생산까지, 여성들의 다양한 재능 발휘\n\n💫 [브랜드 C]\nIT업계 여성들이 만드는 혁신적인 테크 제품\n\n더 많은 여성들의 꿈을 응원하고 싶다면?\n가치살래에서 '여성기업' 찾아보기\n마음에 드는 제품 골라보기\n여러분의 지지와 응원으로\n더 많은 여성들이 꿈꾸는 도전이 현실이 됩니다 ✨\n\n댓글로 여성기업가들을 응원하는 한마디를 남겨주세요!",
      tags: "#가치살래 #착한소비 #여성기업 #우먼파워 #여성창업 #sheowned #기업다양성 #성평등 #경제적자립 #임팩트소비"
    },
    {
      id: 6,
      value: "소년/소녀 가장 후원",
      media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/6earlymost.png",
      caption: "대한민국 소년/소녀 가장 1만 4천여 명\n\n평균 연령 15.7세, 학업과 생계, 미래의 꿈 사이에서 고민하는 청소년들이 있습니다\n너무 일찍 어른이 되어버린 소년.소녀들의 꿈을 응원하는 가치살래의 브랜드들은 어디가 있을까요?\n\n📚 [브랜드 A]\n판매액의 15%를 교육비로 지원\n청소년들의 학업 포기율 줄이기 프로젝트\n\n🍱 [브랜드 B]\n건강한 식사키트 정기배송\n영양가득 한끼로 건강한 성장 지원\n\n가치살래와 함께 청소년들의 꿈을 응원해주세요 ✨\n\n더 많은 이야기가 궁금하다면?\n가치살래에서 '소년소녀가장 후원' 검색\n\n💫 \"꿈꿀 권리가 있는 청소년들에게\n오늘도 내일도, 희망이 되어주세요\"",
      tags: "#가치살래 #착한소비 #소년소녀가장 #청소년후원 #교육지원 #꿈지원 #미래세대 #희망나눔 #청소년복지 #함께자라기"
    },
    {
      id: 5,
      value: "독거노인 후원",
      media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/5oldPerson.png",
      caption: "가치 살래에서 만나는 따뜻한 동행: 독거노인 후원 👵👴\n\n매일 7명의 어르신이 홀로 외롭게 생을 마감하고 계세요.\n코로나19 이후 더욱 심해진 고립감,\n말벗이 그리운 어르신들의 일상을\n우리가 함께 따뜻하게 만들 수 있어요.\n\n이웃을 생각하는 가치살래의 브랜드들\n\n🧡 [브랜드 A]\n어르신들의 손맛을 담은 반찬 브랜드\n매출의 20%로 독거노인 식사 지원\n\n🧦 [브랜드 B]\n시니어 실버모델과 함께하는 의류브랜드\n어르신 일자리 창출로 활력찬 노후 지원\n\n우리의 작은 관심은\n\n정기적인 안부 확인\n영양가 있는 식사 제공\n외로움 예방 프로그램\n안전한 주거환경 조성에 도움을 준답니다.\n\n당신의 소비가 이웃의 따뜻한 미소가 됩니다.\n가치살래에서 '독거노인 후원' 가치를 검색해보세요.\n\n💝 \"이웃이 있어 따뜻한 우리 동네\"\n가치살래가 여러분과 함께 만들어갑니다.",
      tags: "#가치살래 #착한소비 #독거노인후원 #노인복지 #세대공감 #이웃돌봄 #공동체 #실버케어 #효실천 #따뜻한동행"
    },
    {
      id: 4,
      value: "공정무역",
      media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/4fairtrade.png",
      caption: "가치살래에서 만날 수 있는 착한 가치 톺아보기: 공정무역!\n\n전 세계 커피 농가의 80%가 빈곤선 이하의 삶을 살고 있어요.\n매일 마시는 커피 한 잔, 초콜릿 한 조각의 이익 중\n실제 생산자에게 돌아가는 금액은 1% 미만입니다.\n\n생산자들은 열악한 노동환경, 불안정한 수입, 아동노동 착취의 문제로 고통받고 있죠.\n가치살래가 소개하는 공정무역 브랜드들\n\n🌱 [브랜드 A]\n생산자와 직거래하는 프리미엄 커피\n수익의 15%를 농가 발전기금으로 환원\n🍫 [브랜드 B]\n카카오 농가와 상생하는 초콜릿\n공정한 가격으로 안정적인 거래 보장\n\n생산자들의 안정적인 수입 보장, 아동노동 없는 건강한 생산환경, 지역사회의 지속가능한 발전\n\n함께 해볼까요?\n가치살래의 가치 필터링 기능으로 더 많은 공정무역 브랜드를 만나보세요.",
      tags: "#가치살래 #착한소비 #공정무역 #윤리적소비 #지속가능무역 #상생소비 #fairtrade #생산자권리 #책임소비 #윤리적커피"
    },
    {
      id: 3,
      value: "친환경 제품",
      media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/3%08ecofrendly.png",
      caption: "우리나라 연간 플라스틱 사용량 98.2kg/1인\nOECD 국가 중 두 번째로 높은 수준이에요.\n\n그렇다면 이제, 우리의 소비가 달라져야 할 때!\n가치살래가 찾은 친환경 히어로들\n\n🌊 [브랜드 A]\n해양 플라스틱으로 만드는 패션 아이템\n지구를 살리는 업사이클링의 魔法\n🌱 [브랜드 B]\n완전 생분해되는 생활용품\n석유화학 성분 0%, 자연으로 돌아가는 제품\n♻️ [브랜드 C]\n리필스테이션 운영하는 제로웨이스트 숍\n용기부터 포장까지 환경을 생각하는 마음\n\n지구를 생각하는 소비는 미래 세대를 위한 우리의 선물입니다\n더 나은 미래를 위해 가치살래에서 친환경 제품을 찾아보세요!\n\n🌏 지구와 우리의 내일을 위해\n가치살래가 여러분과 함께합니다.",
      tags: "#가치살래 #착한소비 #친환경 #제로웨이스트 #지구를위한선택 #그린라이프 #친환경소비 #sustainable #플라스틱프리 #업사이클링"
    },
    {
      id: 2,
      value: "장애인 채용 기업",
      media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/2disabledperson.png",
      caption: "\"진정한 포용은 기회의 평등에서 시작됩니다\"\n\n2023년 장애인 고용률 3.1% 의무고용률 3.1%는 달성했지만,\n아직도 현장에서는 많은 어려움이 있습니다. 가치살래에서는 장애인, 특히 중증장애인을 채용한 착한 가치를 추구하는 기업을 찾아볼 수 있어요.\n\n🎨 [브랜드 A]\n발달장애인 아티스트와 협업하는 리빙브랜드\n독특한 감성과 진정성 있는 디자인\n☕️ [브랜드 B]\n청각장애인 바리스타가 내리는 커피\n완벽한 맛으로 실력을 인정받는 중\n🌟 [브랜드 C]\n장애인 고용률 30% 달성한 IT기업\n다양성이 혁신을 만드는 현장\n\n이들이 만드는 제품 하나하나에는 자부심과 열정이 담겨있습니다.\n가치살래에서 브랜드를 찾아보세요.! \n\n1단계: 실력있는 브랜드 제품 구경하기\n2단계: 응원 메시지 남기기\n3단계: 새로운 가치소비 시작하기\n\n💙 모두가 자신의 재능을 펼칠 수 있는 세상\n가치살래가 만들어가겠습니다.",
      tags: "#가치살래 #착한소비 #장애인채용 #다양성 #포용적기업 #함께일하기 #장애인고용 #배리어프리 #평등한일터 #더나은일터"
    },
    {
      id: 1,
      value: "유기견 후원",
      media_url: "https://gachiviewer-s3-kc.s3.ap-northeast-2.amazonaws.com/kindnews/1dog.png",
      caption: "가치살래 오늘의 가치: 유기견 후원 🐕\n\n\"한 해 7만 마리. 새로운 가족을 기다리는 반려동물들의 이야기\"\n\n매년 수많은 반려동물들이 거리에 버려지고 있어요.\n한 해 동안 보호소로 구조되는 유기견은 약 7만 마리.\n그 중 새로운 가족을 만나는 아이들은 30%도 되지 않습니다.\n \n유기견 보호소는 부족한 사료와 용품들, 제한된 보호 공간, 부족한 의료 지원, 한정된 인력과 자원으로 고민하고 있어요.\n \n가치살래에서는 '유기견을 후원'하는 브랜드들을 찾을 수 있습니다\n \n🎀 [브랜드 A]\n매출의 10%를 보호소에 기부\n유기견을 위한 프리미엄 사료 제작\n \n🧶 [브랜드 B]\n유기견 입양 캠페인 진행\n친환경 장난감으로 환경까지 생각\n \n제품과 기업을 사회적 가치별로 필터링하여, 고객이 원하는 가치를 실천하는 브랜드와 제품을 쉽게 찾을 수 있습니다.\n \n가치살래에서 '유기견 후원' 가치 검색\n마음에 드는 브랜드의 제품 구경하기\n구매를 통한 간단한 후원 동참\n더 많은 이야기 댓글로 공유하기\n \n💚 내일은 우리 모두가 특별한 누군가의 가족이 되는 그날까지,\n가치살래가 함께하겠습니다.",
      tags: "#가치살래 #착한소비 #퓨어필드 #착한화장품 #비건뷰티 #동물실험반대 #유기견 #유기견보호소 #브랜드A #브랜드B"
    }
  ]

  return (

    <EventPageStyled>
      <Sidebar active="event" />
      <h2>EVENT</h2>
      {
        events.map(company => (
              <EventCard key={company.id} company={company} />
        ))
      }
    </EventPageStyled>
  )
}

export default EventPage
import React from 'react'
import CustomInputStyled from './CustomInputStyled'

function CustomInput(props) {
  // props가 단일 onChange일 경우
  if (typeof props === 'function') {
    return <CustomInputStyled onChange={(e) => props(e)} />;
  }

  // props가 객체일 경우 ({name, value, onChange, ...})
  const { name, value, onChange, type = "text", required } = props;
  return (
      <CustomInputStyled
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          required={required}
      />
  );
}

export default CustomInput
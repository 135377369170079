import React from 'react'
import { FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa'
import ShareStyled from './ShareStyled'

function Share() {
  return (
    <ShareStyled>
      <ul>
        <li>
          <a href="">
            <FaFacebookSquare color='#4a48a2' size={25} />
            <span>Facebook</span>
          </a>
        </li>
        <li>
          <a href="">
            <FaInstagramSquare color='#4a48a2' size={25} />
            <span>Instagram</span>
          </a>
        </li>
      </ul>
    </ShareStyled>
  )
}

export default Share
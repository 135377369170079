import axios from 'axios'
import axiosInstance from '../../Api/axiosInstance';
import React, { useEffect, useState } from 'react'
import CustomSection from '../../Components/Layout/CustomSection/CustomSection'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import BottomBar from '../../Components/MainPage/BottomBar/BottomBar'
import Overview from '../../Components/Shared/Overview/Overview'
import ProductsSection from '../../Components/Shared/ProductsSection/ProductsSection'
import { constants } from '../../constants'
import MainPageStyled from './MainPageStyled'

function MainPage() {
  const [products, setProducts] = useState([]);
  
  let serviceURI = constants.productService + "products?value=&product=&condition=";
  const fetchProducts = async () => {
    setProducts([]);
    console.log("service URI !! : ", serviceURI);
    const response = await axiosInstance.get(serviceURI);

    console.log(response.data);
    console.log(response.status);
    console.log(response.headers);
    console.log(response.statusText);
  
    setProducts(response.data); 
  };
  useEffect(() =>{
    fetchProducts();
  },[]);
 
  const arrProducts = [
    {
      id: 1,
      name: "하루7초 해초샐러드 1Box 7봉지",
      thumbnail: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1639363734961_500.png",
      status: "BEST",
      discountPrice: 12800,
      actualPrice: 16000,
      category: "달하루 양갱"
    },
    {
      id: 2,
      name: "달하루 양갱 세트 14개입",
      thumbnail: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1651626375881_500.jpg",
      status: "BEST",
      discountPrice: 28000,
      actualPrice: 35000,
      category: "달하루 양갱"
    },
    {
      id: 3,
      name: "달하루 양갱 세트 5개입",
      thumbnail: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1651626124123_500.jpg",
      status: "BEST",
      discountPrice: 12000,
      actualPrice: 15000,
      category: "달하루 양갱"
    },
    {
      id: 4,
      name: "[자누담] 100% 생분해 친환경 식물성 봉투 - 드로우스트링 50장",
      thumbnail: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1637891614925_500.jpg",
      status: "BEST",
      discountPrice: 32000,
      actualPrice: 40000,
      category: "자누담"
    },
    {
      id: 5,
      name: "하루7초 해초샐러드 1Box 7봉지",
      thumbnail: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1638143785998_500.jpg",
      status: "BEST",
      discountPrice: 16000,
      actualPrice: 20000,
      category: "하루 7초 해초샐러드"
    },
    {
      id: 6,
      name: "[자누담] 친환경 일회용품 캠핑세트",
      thumbnail: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1656052780015_500.jpg",
      status: "BEST",
      discountPrice: 16000,
      actualPrice: 19000,
      category: "자누담"
    }
  ];
  const arrButtons = [
    {
      title: "All",
      active: true
    },
    {
      title: "달하루 양갱",
      active: false
    },
    {
      title: "자누담",
      active: false
    },
    {
      title: "하루 7초 해초샐러드",
      active: false
    }
  ];
  return (
    <MainPageStyled>
      <Sidebar active="home"/>
      <Overview homeSlider={true} data={[
        "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1643091742720_2500.jpg",
        "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1643091809805_2500.jpg",
        "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1656656729673_2500.jpg",
        "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1643091742720_2500.jpg",
        "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1643091809805_2500.jpg",
        "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1656656729673_2500.jpg"
      ]} />
      <CustomSection homePage={true} title="Gachi #" image="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1657788101038_300.jpg" />
      <div className='marine-desc'>
        <p> 저희 Gachi Shop은 가치있는 사회적 기업을 소개하는 쇼핑몰입니다.
        </p>
      </div>
      <ProductsSection products = {products} buttons={arrButtons} scroll={true}/>
      
      {/* <BottomBar /> */}
    </MainPageStyled>
  )
}

export default MainPage
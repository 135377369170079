import CheckBox from '../../../Assets/check.svg'
import styled from "styled-components";
const PhotoReviewStyled = styled.div `
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 20px;
  color: var(--app-black);
  label {
    margin-left: 5px;
  }
  
`

export default PhotoReviewStyled;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BlackButton from '../../Components/Shared/BlackButton/BlackButton';
import Sidebar from '../../Components/Layout/Sidebar/Sidebar';

const EntryCompleteStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
  padding: 0 20px;
    margin-top:  auto;

  .message {
    margin-bottom: 40px;
    font-family: "Nanum Gothic", sans-serif;
    line-height: 1.6;
    white-space: pre-line;
  }
`;

function EntryComplete() {
    const navigate = useNavigate();

    return (
        <EntryCompleteStyled>
            <Sidebar />
            <div className="message">
                입점 신청이 완료되었습니다.
                {'\n'}
                결과는 1주일 이내로 연락처를 통해 안내 드리겠습니다.
            </div>
            <BlackButton
                title="홈으로 이동"
                width="200px"
                onClick={() => navigate('/')}
            />
        </EntryCompleteStyled>
    );
}

export default EntryComplete;
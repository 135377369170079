import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// import CustomSection from '../../Components/Layout/CustomSection/CustomSection'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import Overview from '../../Components/Shared/Overview/Overview'
import ProductsSection from '../../Components/Shared/ProductsSection/ProductsSection'
// import FilterButtons from '../../Components/Shared/FilterButtons/FilterButtons'
import FilterSection from '../../Components/Shared/ProductsSection/FilterSection'
import { constants, valuefilterkeywords, productfilterkeywords } from '../../constants'
import ProductsPageStyled from './ProductsPageStyled'
import axios from 'axios'
import axiosInstance from '../../Api/axiosInstance';
// import FilterButtons from '../../Components/Shared/FilterButt  ons/FilterButtons'

function ProductsPage(props) {
  const [products, setProducts] = useState([]);

  const location = useLocation();
  const state = location.state;

  // const valueButtons;
  // valueButtons={};
  // const productButtons={};
        
  
  let serviceURI = constants.baseURI + constants.productService + "products?value="+state.value+"&product="+state.product+"&condition=";
  const fetchProducts = async () => {
    setProducts([]);

    console.log("start get product!!");

    const response = await axiosInstance.get(serviceURI);
    console.log('Active Value Buttons:', serviceURI);
    console.log(response.data);
    console.log(response.status);
    console.log(response.headers);
    console.log(response.statusText);
  
    setProducts(response.data); 
  };

  // const [activeValueButtons, setActiveValueButtons] = useState([]);
  // const [activeProductButtons, setActiveProductButtons] = useState([]);

  // const handleFilterButtonClick = (activeValues, activeProducts) => {
  //   // 각각의 변수에 저장
  //   setActiveValueButtons(activeValues);
  //   setActiveProductButtons(activeProducts);
  //   // 문자열로 변환
  //   const valueTag = activeValueButtons.join(','); // 쉼표로 구분된 문자열
  //   const productTag = activeProductButtons.join(',');
    
    const handleFilterButtonClick = (valueTag, productTag, logicOperator) => {

    // 결과 출력
    console.log('Active Buttons:', valueTag, " / ", productTag, logicOperator);
    serviceURI = constants.baseURI + constants.productService + "products?value="+valueTag+"&product="+productTag+"&condition="+logicOperator;
    // /products?value={value}&product={product}
    
    fetchProducts();
  };



  // menu :
  useEffect(() =>{
    fetchProducts();
  },[state.value, state.product]);
 
  return (
    <ProductsPageStyled>
      <Sidebar />
      
      <Overview
      homeSlider={false}
      data={[
          "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1638780041331_2500.jpg"
      ]} />
      {/* <CustomSection title={state.title} image="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1657788545814_300.png" /> */}

      {/* <FilterButtons></FilterButtons> */}

      <FilterSection
        // valueButtons={valueButtons}
        // onClickValue={onClickValue}
        // productButtons={productButtons}
        // onClickProduct={onClickProduct}
        initialValueButtons={state.value}
        initialProductButtons={state.product}
        onFilterButtonClick={handleFilterButtonClick}
      />

      <ProductsSection 
        title={state.title}
        products={products}
        // valueButtons={valuefilterkeywords.map(keyword => ({ title: keyword.text, active: false }))}
        // productButtons={productfilterkeywords.map(keyword => ({ title: keyword.text, active: false }))}
        // onFilterButtonClick={handleFilterButtonClick}  // 필터값 전달 함수
      />
    </ProductsPageStyled>
  )
}

export default ProductsPage
import { createContext, useReducer } from "react";

const headerReducer = (initialState, action) => {
  switch (action.type){
    case 'SHOW_HEADER':
      return {...initialState, showHeader: true, showSearchBar: false}
    case 'HIDE_HEADER':
      return {...initialState, showHeader: false, showSearchBar: true}

    default:
      return {...initialState}
  }
}

const HeaderContext = createContext();

export const HeaderProvider = ({children}) => {
  const [state, dispatch] = useReducer(headerReducer, {
    showHeader: true,
    showSearchBar: false
  })

  return <HeaderContext.Provider value={{state: state, dispatch: dispatch}}>
    {children}
  </HeaderContext.Provider>
}

export default HeaderContext;
import React, { useState, useEffect } from 'react'
import TermsConditionsStyled from './TermsConditionsStyled'

function TermsConditions({ onMarketingFlagChange, onValidityChange }) {
    const [checkboxes, setCheckboxes] = useState({
        everyone: false,
        termsOfUse: false,
        mature: false,
        marketing: false
    });

    // 모든 필수 약관이 체크되었는지 확인
    useEffect(() => {
        const isValid = checkboxes.termsOfUse && checkboxes.mature;
        onValidityChange(isValid);
    }, [checkboxes.termsOfUse, checkboxes.mature, onValidityChange]);

    // 마케팅 동의 상태 변경 시 부모 컴포넌트에 알림
    useEffect(() => {
        onMarketingFlagChange(checkboxes.marketing);
    }, [checkboxes.marketing]);

    // 개별 체크박스 핸들러
    const handleSingleCheck = (name) => {
        setCheckboxes(prev => {
            const newState = { ...prev, [name]: !prev[name] };

            // 모두 체크되었는지 확인하여 '모두 동의' 체크박스 상태 업데이트
            const allChecked = Object.values(newState).every(value => value);
            if (newState.everyone !== allChecked) {
                newState.everyone = false;
            }

            return newState;
        });
    };

    // 모두 동의 체크박스 핸들러
    const handleAllCheck = () => {
        setCheckboxes(prev => {
            const newValue = !prev.everyone;
            return {
                everyone: newValue,
                termsOfUse: newValue,
                mature: newValue,
                marketing: newValue
            };
        });
    };

    return (
        <TermsConditionsStyled>
            <div>
                <div className="checkbox-wrapper">
                    <input
                        type="checkbox"
                        id="everyone"
                        checked={checkboxes.everyone}
                        onChange={handleAllCheck}
                    />
                </div>
                <span>모두 동의합니다.</span>
            </div>
            <div>
                <div className='checkbox-wrapper'>
                    <input
                        type="checkbox"
                        id="terms-of-use"
                        checked={checkboxes.termsOfUse}
                        onChange={() => handleSingleCheck('termsOfUse')}
                    />
                </div>
                <span>
          (필수) <span style={{color: "var(--app-pink)"}}>이용약관과 개인정보 수집 및 이용</span>에 동의합니다.
        </span>
            </div>
            <div>
                <div className="checkbox-wrapper">
                    <input
                        type="checkbox"
                        id="mature"
                        checked={checkboxes.mature}
                        onChange={() => handleSingleCheck('mature')}
                    />
                </div>
                <span>
          (필수) 만 14세 이상입니다. <br />
          만 19세 미만의 미성년자가 결제 시 법정대리인이 거래를 취소할 수 있습니다.
        </span>
            </div>
            <div>
                <div className="checkbox-wrapper">
                    <input
                        type="checkbox"
                        id="receive-email"
                        checked={checkboxes.marketing}
                        onChange={() => handleSingleCheck('marketing')}
                    />
                </div>
                <span>
          (선택) 이메일 및 SMS 마케팅 정보 수신에 동의합니다.
          회원은 언제든지 회원 정보에서 수신 거부로 변경할 수 있습니다.
        </span>
            </div>
        </TermsConditionsStyled>
    )
}

export default TermsConditions
import React from 'react'
import CustomSectionImg from '../../../Assets/CustomSectionImg.jpg';
import CustomSectionStyled from './CustomSectionStyled'

function CustomSection({title, homePage, image}) {
  return (
    <CustomSectionStyled homePage={homePage}>
      <img src={image} />
      <div>
        <p>
          {title}
        </p>
      </div>
    </CustomSectionStyled>
  )
}

export default CustomSection
import React from "react";
import UnderConstructionSVG from "../../Assets/under_construction.svg";
import NotYetBuiltStyled from "./NotYetBuiltStyled";
import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
import { BsExclamationCircle } from "react-icons/bs";

export default function NotYetBuilt() {
  return (
    <NotYetBuiltStyled>
      <Sidebar />
      <div className="content">
        <BsExclamationCircle size={"300px"} color="#044CA5" />
        <h3>현재 준비중인 기능입니다.</h3>
          <p>
              현재 이 페이지는 개발 중입니다. <br/>
              빠른 시일 내에 서비스를 제공해 드리도록 하겠습니다.
          </p>
          <button
          onClick={() => {
            window.history.back();
          }}
        >
          돌아가기
        </button>
      </div>
    </NotYetBuiltStyled>
  );
}

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/Layout/Sidebar/Sidebar';
import BlackButton from '../../Components/Shared/BlackButton/BlackButton';
import CustomInput from '../../Components/Shared/CustomInput/CustomInput';
import styled from 'styled-components';
import EntryPageStyled from "./EntryPageStyled";

function EntryPage() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        companyName: '',
        email: '',
        phone: '',
        website: '',
        introduction: '',
        products: '',
        businessNumber: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // API 엔드포인트는 실제 서버 주소로 변경 필요
            // const response = await fetch('/api/entry-applications', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(formData)
            // });
            //
            // if (response.ok) {

            if (formData.companyName && formData.email && formData.phone && formData.introduction && formData.products && formData.businessNumber) {
                alert('입점 신청이 완료되었습니다.');
                navigate('/entry/complete');
            } else {
                alert('입점 신청 중 오류가 발생했습니다.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('서버 통신 중 오류가 발생했습니다.');
        }
    };

    return (
        <EntryPageStyled>
            <Sidebar active="contact" />
            <div>
                <h2>입점 신청</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="companyName">기업명 *</label>
                        <CustomInput
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="businessNumber">사업자등록번호 *</label>
                        <CustomInput
                            name="businessNumber"
                            value={formData.businessNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">이메일 *</label>
                        <CustomInput
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="phone">연락처 *</label>
                        <CustomInput
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="website">웹사이트</label>
                        <CustomInput
                            name="website"
                            value={formData.website}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="introduction">기업 소개 *</label>
                        <textarea
                            name="introduction"
                            value={formData.introduction}
                            onChange={handleChange}
                            placeholder="기업의 미션, 비전, 주요 활동 등을 소개해 주세요."
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="products">취급 제품 *</label>
                        <textarea
                            name="products"
                            value={formData.products}
                            onChange={handleChange}
                            placeholder="판매 희망 제품의 종류, 특징, 가격대 등을 설명해 주세요."
                            required
                        />
                    </div>

                    <div className="submit">
                        <BlackButton
                            type="submit"
                            title="신청하기"
                            width="50%"
                        />
                    </div>
                </form>
            </div>
        </EntryPageStyled>
    );
}

export default EntryPage;
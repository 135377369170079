import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import BlackButton from '../../Components/Shared/BlackButton/BlackButton'
import CustomInput from '../../Components/Shared/CustomInput/CustomInput'
import WhiteButton from '../../Components/Shared/WhiteButton/WhiteButton'
import { constants } from '../../constants'
import LoginPageStyled from './LoginPageStyled'
import axios from 'axios'
import qs from 'qs'
import axiosInstance from '../../Api/axiosInstance';

function LoginPage() {
  // const loginURI = constants.baseURI + constants.authService + "login";
  const loginURI = constants.authService + "login";
  // const loginURI = "http://localhost:8086/login";

  
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    // navigate("/");
  }

  var user_id = "";
  // const setId = (e) => {
  //   user_id = e.target.value // <- 변경 후
  // }

  
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");

  const signup = (e) => {
    navigate("/signup");
  }

  const logout = (e) => {
    sessionStorage.setItem("isLogined", false);
  }

  const login = (e) => {
    //e.preventDefault();
    console.log("id : " + id);
    console.log("password : " + password);

    if(!id || !password) {
      return alert("아이디 비밀번호를 입력해주세요.");
    } 

    let body = {
      username : id,
      password : password,
      devieceId : ""
    };
/*
    let options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: body,
      loginURI,
    };
*/
    // 리소스 접근 허용
    // axios.defaults.headers['Access-Control-Allow-Origin'] = 'http://localhost:9999';
    // 서로 다른 도메인간 쿠키 전달 허용
    // axios.defaults.withCredentials = true;

    if(localStorage.getItem("deviceId") != null) {
      body.devieceId = localStorage.getItem("deviceId");
      console.log(body.devieceId);
    }

    // axios.post(loginURI, qs.stringify(body)
    axiosInstance.post(loginURI, qs.stringify(body)
    // , { withCredentials: true }
    ).then((res) => {
        // console.log(res.data);
        if(res.status === 200) {
          console.log("로그인");
          console.log(res.data);

          

          localStorage.setItem("deviceId", res.data.deviceId);
          sessionStorage.setItem("isLogined", true);
          sessionStorage.setItem("userId", "userId");

          navigate("/");
          
        } else if(res.status === 401) {
          alert("ID, Password가 일치하지 않습니다");
        } else {
          console.log(res.data.code + " " + res.data.body);
          console.log(res.data);
          console.log(res.status);
        }
        
      });

  }

  useEffect(() => {
      console.log("when ? ");
  }, [])

  return (
    <LoginPageStyled>
      <Sidebar />
      <div>
        <h2>로그인</h2>
        <form onSubmit={(e)=>handleSubmit(e)}>
          <div className="email">
            <label htmlFor="email">이메일</label>
            {/* <CustomInput onChange={(e) => setId(e)} /> */}
            <input type="text" onChange={(e) => setId(e.target.value)} />
          </div>
          <div className="password">
            <label htmlFor="password">비밀번호</label>
            {/*<CustomInput value={password} onChange={(e) => setPassword(e.target.value)} />*/}
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
          </div>
          <div className="other-options">
            <span className="forgot">
              <Link to="/forgot-password">비밀번호 찾기</Link>
            </span>
            <span className="non-member">비회원 주문 조회하기</span>
          </div>
          <div className='buttons'>
            <BlackButton title="로그인하기" width="60%" type="submit" onClick={(e)=>login(e)}/>
            <WhiteButton title="회원 가입하기" width="60%" onClick={(e)=>signup(e)} />
          </div>
          <div className="socials">
            {/*We will add social login buttons here. 
              they are provided with these social medias
              by default
            */}
          </div>
        </form>
      </div>
    </LoginPageStyled>
  )
}

export default LoginPage
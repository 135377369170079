import styled from "styled-components";
const CartPageStyled = styled.div `
  padding: 15px;
  font-weight: 300;
  .place-order {
    margin: 20px 0px;
  }
  .naver {
    display: flex;
    justify-content: center;
    margin: 20px 0px
  }
  div.totals {
    border-top: 1px solid var(--app-border-color);
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--app-border-color);
    padding: 10px 0px;;
  }
  div.totals>div, div.sum {
    display: flex;
    justify-content: space-between;
    margin: 15px 0px;
  }
  div.sum {
    font-weight: 500;
    padding-bottom: 50px;
  }
`
export default CartPageStyled;
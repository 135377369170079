import styled from "styled-components";
const BlackButtonStyled = styled.button `
    padding: 15px 30px;
    border-radius: 30px;
    width: ${props => props.width ? props.width:'100%'};
    margin: ${props => props.fontSize ? `${props.fontSize}em 0` : '1em 0'};
    background-color: var(--app-black);
    color: #fff;
    border: none;
    &:hover {
        background-color: var(--app-hover-black);
    }

`
export default BlackButtonStyled;
import React from "react";
import { useEffect } from "react";
import ProductCardStyled from "./ProductCardStyled";

function ProductCard({ product, related }) {
  useEffect(() => {}, []);
  return (
    <ProductCardStyled product={product} related={related}>
      <div className="product-thumbnail">
        <div className="status-badge">
          <span>{product.tagsValue}</span>
        </div>
      </div>
      <div className="product-details">
        <p className="product-name">{product.name}</p>
        <div className="prices">
          {/* <span className='discount-price'>
            {product.discountPrice}원
          </span> */}
          <span className="actual-price">{product.price}원</span>
        </div>
      </div>
    </ProductCardStyled>
  );
}

export default ProductCard;

import styled from "styled-components";
const ReviewDetailStyled = styled.div `
  .text-center {
    text-align: center;
  }
  h2 {
    margin-bottom: 5px;
  }
  p {
    margin: 40px 0px;
    font-weight: 300;
    color: var(--app-black);
  }
  .img {
    width: 100px;
    height: 100px;
    background-image: url(${props=>props.backgroundImg});
    background-size: cover;
    background-position: center;
  }
`
export default ReviewDetailStyled;
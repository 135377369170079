import React, { useState } from 'react'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import CustomInput from '../../Components/Shared/CustomInput/CustomInput'
import PostQuestionStyled from './PostQuestionStyled'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import BlackButton from '../../Components/Shared/BlackButton/BlackButton'
import { FaCamera } from 'react-icons/fa'
import WhiteButton from '../../Components/Shared/WhiteButton/WhiteButton'

function PostQuestion() {
  const [data, setData] = useState('');
  return (
    <PostQuestionStyled>
      <Sidebar />
      <div>
        
        <form>
          <div className="subject">
            <label htmlFor="subject">Subject</label>
            <CustomInput />
          </div>
          <div className="writer">
            <label htmlFor="writer">Writer</label>
            <CustomInput />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <CustomInput />
          </div>
          <div className="password">
            <label htmlFor="password">Password</label>
            <CustomInput />
          </div>
          <div className="confirm-password">
            <label htmlFor="confirm-password">Confirm Password</label>
            <CustomInput />
          </div>
          <div>
            <input type="checkbox" />
            <span>
              <span style={{color: "var(--app-pink)"}}>개인정보 수집 및 이용</span>에 동의합니다.  
            </span>
          </div>   
          <div id="editor">
            <CKEditor 
              editor={ClassicEditor}
              onChange={(e, editor)=>setData(editor.getData())}
            />
          </div>
          <div className="upload">
            <p>Upload Image</p>
            <BlackButton width="fit-content">
              <FaCamera size={20} color="white" />
            </BlackButton>
          </div>
          <div className='set-secret'>
            <input type="checkbox" />
            <span>Set secret</span>
          </div>
          <div className='buttons'>
            <WhiteButton title="RETURN TO LIST" />
            <BlackButton title="SAVE" />
          </div>
        </form>
      </div>
    </PostQuestionStyled>
  )
}

export default PostQuestion
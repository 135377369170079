import styled from "styled-components";

const ProductDetailStyled = styled.div `
  padding: 15px;
  font-family: var(--app-font-family);
  h2 {
    font-size: 30px;
    font-family: var(--app-font-family);
    font-weight: 500;
    color: var(--app-black);
    margin-top: 30px;
  }
  .product-detail.prices {
    margin: 40px 0px;
    font-size: 20px;
    font-family: var(--app-font-family);
    font-weight: 400;
  }
  .product-detail.prices .actual-price {
    color: var(--app-pink);
    padding-right: 5px;
  }
  .product-detail.prices .discount-price {
    color: var(--app-semi-black);
    text-decoration: line-through;
  }
  .delivery-fee {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: var(--app-font-family);
    font-size: 13px;
    line-height: 16px;
    color: var(--app-black);
  }
  .delivery-fee .title {
    font-weight: 600;
    white-space: nowrap;
    padding-right: 20px;
    font-family: var(--app-font-family);
    font-size: 14px;
  }
  .quantity {
    font-size: 14px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 20px;
  }
  .quantity p {
    margin-bottom: 5px;
    font-weight: 300;
  }
  .quantity input {
    
    font-weight: 500;

  }
  .order div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding-bottom: 20px;
  }
  .order div span:first-child {
    color: var(--app-black);
    font-weight: 500;
    
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 40px 0px;
    
  }
  .buttons button {
    padding: 15px 30px;
    border-radius: 30px;
    width: 47%;
  }
  .buttons button:first-child {
    background-color: var(--app-black);
    color: #fff;
    border: none;
  }
  .buttons button:first-child:hover {
    background-color: #4e4e4e;
  }
  .buttons button:nth-child(2) {
    background-color: #fff;
    color: var(--app-black);
    border: 1px solid var(--app-black);
  }
  .buttons button:nth-child(2):hover {
    background-color: var(--app-black);
    color: #fff;
  }
  .naver a {
    display: flex;
    justify-content: center;
    
  }
  .naver a img {
    border-top: 2px solid var(--app-black);
    padding-top: 10px;
  }
  div.share {
    position: relative;
  }
  .share button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-family: var(--app-font-family);
    font-weight: 300;
  }
  .bottom img {
    max-width: 100%;
    display: block;
  }
  div.related-products {
    margin: 30px 0px;
  }

  .share button {
    margin-top: 20px;
  }

  //reviews
  .reviews {
    border-bottom: 1px solid #d6d6d6;
  }

  .reviews>div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    font-family: var(--app-font-family);
    border-bottom: 1px solid #d6d6d6;
  }
  .reviews>div>div {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .reviews>div>div>p {
    font-size: 30px;
    font-weight: 500;
    padding-right: 10px;
  }
  .reviews>div>div>span, .reviews label {
    font-size: 15px;
    font-weight: 300;
  }
  .reviews label {
    padding-left: 7px;
  }
  .photo-review {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .post-review {
    display: flex;
    justify-content: right;
    padding: 20px 0px;
  }
`



export default ProductDetailStyled;
import React from 'react'
import CustomSection from '../../Components/Layout/CustomSection/CustomSection'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import Overview from '../../Components/Shared/Overview/Overview'
import ProductsSection from '../../Components/Shared/ProductsSection/ProductsSection'

function SecondsADay() {
  return (
    <div>
      <Sidebar active="7seconds-a-day" />
      <Overview homeSlider={false} data={[
        'https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1636941896780_2500.jpg'
      ]} />
      <CustomSection title="하루 7초" image="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1657788881483_300.png" />
      <ProductsSection 
        products = {[
          {
            id: 1,
            name: "하루7초 해초샐러드 1Box 7봉지",
            thumbnail: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/product/image_1639363734961_500.png",
            status: "NEW",
            discountPrice: 12800,
            actualPrice: 16000,
            category: "달하루 양갱"
          }
        ]}
        buttons={
          [
            {
              title: "All",
              active: true
            },
            {
              title: "달하루 양갱",
              active: false
            }
          ]
        }
      />
    </div>
  )
}

export default SecondsADay
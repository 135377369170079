import React, { useState, useEffect } from 'react'
import FilterButtons from './FilterButtons';
import FilterSectionStyled from './FilterSectionStyled';
import BlackButton from '../../../Components/Shared/BlackButton/BlackButton';
import { constants, valuefilterkeywords, productfilterkeywords } from '../../../constants'
import RadioGroupStyled from '../../../Components/Shared/RadioButton/RadioGroupStyled';
import RadioGroup from '../../../Components/Shared/RadioButton/RadioGroup';

// function FilterSection({ valueButtons, onClickValue, productButtons, onClickProduct, onFilterButtonClick }) {
function FilterSection({ onFilterButtonClick, initialValueButtons, initialProductButtons }) {

  // 필터 초기값 선택
  useEffect(() => {
    const updatedValueButtons = valueButtons.map(button => ({
        ...button,
        active: initialValueButtons.includes(button.title), // 전달받은 값과 비교하여 active 설정
    }));
    setValueButtons(updatedValueButtons);

    const updatedProductButtons = productButtons.map(button => ({
        ...button,
        active: initialProductButtons.includes(button.title), // 전달받은 값과 비교하여 active 설정
    }));
    setProductButtons(updatedProductButtons);
}, [initialValueButtons, initialProductButtons]); // 초기값이 변경될 때마다 실행



  const [activeValueButtons, setActiveValueButtons] = useState([]);
  const [activeProductButtons, setActiveProductButtons] = useState([]);

  // const handleFilterButtonClick = (activeValues, activeProducts) => {

    const [valueButtons, setValueButtons] = useState(valuefilterkeywords.map(keyword => ({ title: keyword.text, active: false })));
    const [productButtons, setProductButtons] = useState(productfilterkeywords.map(keyword => ({ title: keyword.text, active: false })));
    
    // 필터 실행 버튼!!
  const handleFilterButtonClick = () => {
    const activeValueButtons = valueButtons.filter(button => button.active).map(button => button.title);
    const activeProductButtons = productButtons.filter(button => button.active).map(button => button.title);
    
    // 각각의 변수에 저장
    setActiveValueButtons(activeValueButtons);
    setActiveProductButtons(activeProductButtons);
    // 문자열로 변환
    const valueTag = activeValueButtons.join(','); // 쉼표로 구분된 문자열
    const productTag = activeProductButtons.join(',');

    // onFilterButtonClick 함수를 호출하여 부모로 전달
    onFilterButtonClick(valueTag, productTag, logicOperator);
  };

  const onClickValue = (index) => {
    // 선택된 버튼의 상태만 토글
    setValueButtons(
      valueButtons.map((button, i) => {
        if (i === index) {
          button.active = !button.active;  // 현재 버튼의 active 상태를 반전
        }
        return button;
      })
    );

  };

  const onClickProduct = (index) => {
    // 선택된 버튼의 상태만 토글
    setProductButtons(
      productButtons.map((button, i) => {
        if (i === index) {
          button.active = !button.active;  // 현재 버튼의 active 상태를 반전
        }
        return button;
      })
    );

  };



  /*
    And or 필터
  */

    const [logicOperator, setLogicOperator] = useState('and');

    const handleRadioButtonChange = (event) => {
      setLogicOperator(event.target.value);
    };

    const options = [
      { label: 'And', value: 'and' },
      { label: 'Or', value: 'or' },
    ];

  return (
    <FilterSectionStyled>
      가치필터
      <FilterButtons buttons={valueButtons} onClick={onClickValue} />

      제품 필터
      <FilterButtons buttons={productButtons} onClick={onClickProduct} />

      <RadioGroupStyled>
        <RadioGroup 
          options={options} 
          selectedValue={logicOperator} 
          onChange={handleRadioButtonChange} 
        />
      </RadioGroupStyled>
      <BlackButton onClick={handleFilterButtonClick}> 검색하기 </BlackButton>
    </FilterSectionStyled>
  );
}

export default FilterSection;
import styled from "styled-components";
import { getImageUrlFromTag } from "../../../Utils/getImgUrl";
const ProductCardStyled = styled.div`
  width: ${(props) => (props.related ? "100%" : "140px")};
  min-height: ${(props) => (props.related ? "300px" : "160px")};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin-right: 20px;
  .product-thumbnail {
    width: 100%;
    height: ${(props) => (props.related ? "300px" : "160px")};
    background-image: url(${(props) =>
      getImageUrlFromTag(props.product.image) || props.product.product_image});
    background-position: center;
    background-size: cover;
    padding: 5px;
    margin: ${(props) => (props.related ? "30px 0px" : "0px")};
  }
  .status-badge {
    background-color: var(--app-pink);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .status-badge span {
    color: #fff;
    font-size: 9px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .product-details {
    padding: 8px 4px 8px 4px;
    text-align: center;
  }
  .product-details p {
    font-size: 10pt;
    color: var(--app-black);
    line-height: 1.5em;
    max-height: 3em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .prices {
    display: flex;
    justify-content: center;
    font-size: 12pt;
  }
  .prices span:first-child {
    color: var(--app-pink);
  }
  .prices span:nth-child(2) {
    color: var(--app-semi-black);
    text-decoration: line-through;
    padding-left: 5px;
  }
`;

export default ProductCardStyled;

import React from 'react'
import {useNavigate} from "react-router-dom";
import EventCardStyled from './EventCardStyled'

const EventCard = ({ company }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/event/${company.id}`, { state: { company } });
    };

    return (
        <EventCardStyled onClick={handleClick}>
            <img src={company.media_url} alt={company.value} />
            {/*<h3>{company.value}</h3>*/}
        </EventCardStyled>
    );
};


// function EventCard({event}) {
//   return (
//     <EventCardStyled>
//       <img src={event.media_url} alt={event.value} />
//       <div className='overlay'>
//         {/*<div className='inner'>*/}
//         {/*  /!*<p>{event.value}</p>*!/*/}
//         {/*  /!*<p>{event.date}</p>*!/*/}
//         {/*  /!*<p>{event.time}</p>*!/*/}
//         {/*</div>*/}
//       </div>
//     </EventCardStyled>
//   )
// }

export default EventCard
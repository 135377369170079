import React from 'react'
import MarineInnovation from '../../Components/AboutPage/MarineInnovation/MarineInnovation'
import MarineOverview from '../../Components/AboutPage/MarineOverview/MarineOverview'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import AboutPageStyled from './AboutPageStyled'

function AboutPage() {
  return (
    <AboutPageStyled>
      <Sidebar active="about"/>
      <MarineOverview />
      <MarineInnovation />
    </AboutPageStyled>
  )
}

export default AboutPage
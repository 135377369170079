import React from 'react';
import RadioButtonStyled from './RadioButtonStyled';

function RadioButton({ label, value, checked, onChange }) {
  return (
    <RadioButtonStyled>
      <label>
        <input 
          type="radio" 
          value={value} 
          checked={checked} 
          onChange={onChange} 
        />
        {label}
      </label>
    </RadioButtonStyled>
  );
}

export default RadioButton;

// EventDetailPageStyled.js
import styled from 'styled-components';

export const DetailPageStyled = styled.div`
  padding: 0 20px 20px 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

export const ImageSection = styled.div`
  width: 100%;
  height: 400px;
  margin-bottom: 30px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;

export const ContentSection = styled.div`
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }

  .caption {
    font-size: 1.1rem;
    line-height: 1.6;
    white-space: pre-line;
    margin-bottom: 30px;
  }
`;

export const TagsSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 6px;  // 간격 줄임
    margin: 0 0 15px 0;
    overflow-x: auto;
    padding: 0;

    &::-webkit-scrollbar {
        width: 0;
    }

    span {
        padding: 4px 12px;  // 상하 패딩 조정
        font-size: 13px;
        font-family: Rubik, sans-serif;
        margin: 0;  // 마진 제거
        border-radius: 50px;
        border: 1.5px solid var(--app-blue);
        background-color: #fff;
        height: auto;  // 높이를 자동으로 설정
        line-height: 1.2;  // 줄 높이 조정
        display: inline-flex;  // inline-flex로 변경
        align-items: center;  // 세로 중앙 정렬
        width: fit-content;
        white-space: nowrap;
    }
`;
import React from 'react'
import CustomInput from '../CustomInput/CustomInput'
import CustomTextArea from '../CustomTextArea/CustomTextArea'
import PostCommentStyled from './PostCommentStyled'

function PostComment() {
  return (
    <PostCommentStyled>
      <div className="writer">
        <label htmlFor="writer">Writer</label>
        <CustomInput />
      </div>
      <div className="content">
        <label htmlFor="content">Content</label>
        <CustomTextArea />
      </div>
    </PostCommentStyled>
  )
}

export default PostComment
import React, { useState } from 'react'
import { BiX } from 'react-icons/bi'
import CartTable from '../../Shared/CartTable/CartTable'
import CartItemStyled from './CartItemStyled'

function CartItem({item}) {
  const [quantity, setQuantity] = useState(item.quantityInCart);
  const onChangeQuantity = (e) => setQuantity(e.target.value)
  return (
    <CartItemStyled backgroundImg={item.image}>
      <div>
        <div>
          <div className="img">

          </div>
        </div>
        <div className='item-details'>
          <p>{item.productName}</p>
          <CartTable quantity={quantity} onChangeQuantity={onChangeQuantity} />
          <p>{item.price * item.quantityInCart} won</p>
        </div>
        <div>
          <BiX />
        </div>
      </div>
      <div>
        <p>
          Price {item.price * item.quantityInCart} won + shipping cost {item.shippingFee} won
        </p>
        <p>Free with purchase of {item.wonFree}won or more</p>
      </div>
    </CartItemStyled>
  )
}

export default CartItem
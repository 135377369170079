import React from 'react'
import PostComment from '../PostComment/PostComment';
import Rating from '../Rating/Rating';
import DetailComponentStyled from './DetailComponentStyled'

function DetailComponent({element}) {
  return (
    <DetailComponentStyled backgroundImg={element.image}>
      <div className='details'>
        <div className="text-center">
          <h2>{element.subject}</h2>
          <Rating rating={element.rating} />
          <p>
            {element.writer} element / {element.date}
          </p>
        </div>
        <p>
          {
            element.message
          }
        </p>
      </div>
      {
        element.image && <div className='img'>
        </div>
      }
      <p>
        Comments ({element.comments})
      </p>
      <PostComment />
    </DetailComponentStyled>
  )
}

export default DetailComponent;
import React from "react";
import Rating from "../../Shared/Rating/Rating";
import ReviewStyled from "./ReviewStyled";

function Review({ review, rating }) {
  return (
    <ReviewStyled backgroundImg={review.image}>
      <div className="detail">
        <div className="detail-header">
          <Rating rating={review.rating || rating} />{" "}
          <span style={{ marginLeft: "5px" }}>{review.title}</span>{" "}
          <span style={{ marginLeft: "5px" }}>
            {review.written_date || review.date}
          </span>
        </div>
        <div className="message">{review.content || review.message}</div>
      </div>
      <div className="img"></div>
    </ReviewStyled>
  );
}

export default Review;

import React from 'react'
import WhiteButtonStyled from './WhiteButtonStyled'

function WhiteButton({type, title, width, onClick, children}) {
  return (
    <WhiteButtonStyled type={type} width={width} onClick={(e)=>onClick(e)}>
      {
        title ? title : children 
      }
    </WhiteButtonStyled>
  )
}

export default WhiteButton
import styled from "styled-components";
const ReviewStyled = styled.div `
  color: var(--app-black);
  font-family: var(--app-font-family);
  font-size: 15px;

  display: flex;
  .detail {
    padding: 15px 0px;
    display: block  !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 100px);
  }
  .detail-header {
    display: block;
    font-size: 14px;
    margin: 0px 0px 20px 0px;
  }
  
  .img {
    height: 90px !important;
    width: 90px !important;
    margin-top: 15px;
    margin-left: 15px;
    height: 90px;
    width: 90px;
    background-image: url(${props=>(props.backgroundImg)});
    background-size: cover;
    background-position: center;
  }
  .message {
    font-size: 13px;
    line-height: 1.9em;
    max-height: 3.8em;
    overflow: hidden;
    margin: 5px 0px;
  }
`

export default ReviewStyled;
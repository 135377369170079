import styled from "styled-components";
const CompanyPageStyled = styled.div `
  margin-bottom: 120px;
  padding : 0px 20px 0px 20px;
  
    .company {
        margin: 32px 0px 32px 0px;
    
        > div:first-child {
            width: 100%;
            margin: 15px 0 10px 0;
    
            /* Slider 스타일 override */
            > div {
                height: auto !important;
    
                .slide {
                    position: relative !important;
                    padding-bottom: 56.25%; /* 임시로 16:9 비율 유지. 이미지가 로드되면 실제 비율로 조정됨 */
                }
            }
        }
    }
    
    
  .introduction {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  h2 {
    font-family: "Nanum Gothic", sans-serif;
    color: var(--app-black);
    margin-top: 15px;
    margin-bottom: 12px;
  }
  p {
    color: var(--app-black);
    line-height: 35px;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 15px;
  }
  img {
      //height: 100%;
      width: 100%;
      object-fit: cover; /* 또는 contain */
      aspect-ratio: 16/9;
  }

    .overview img {
        //height: auto;
        width: 100%;
        //object-fit: contain;
        //aspect-ratio: 1; /* 1:1 비율로 설정 */
        aspect-ratio: 16/9;
        object-fit: cover;
    }
    
  .right-thoughts h2 {
    line-height: 1.4em;
    margin: 50px 0px;
    font-size: 23px;
  }
  
`
export default CompanyPageStyled;
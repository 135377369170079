import React from 'react'
import { FaPhoneAlt, FaEnvelope, FaInstagram } from "react-icons/fa";


import FooterStyled from './FooterStyled';
import EscrowInicisPay from '../../../Assets/escrow_inicisPay.png';

function Footer() {
  return (
    <FooterStyled>
      <div className='media'>
        <FaPhoneAlt size={20} color="#fff" />
        <FaEnvelope size={20} color="#fff" />
        <FaInstagram size={20} color="#fff" />
      </div>
      <div className='links'>
        <span>이용약관</span>
        <span>개인정보처리방침</span>
        <span>사업자정보확인</span>
      </div>
      <div>
        상호: (주)비즈팩토리 | 대표: 김정필 | 개인정보관리책임자: (주)비즈상회 | 이메일: <span className='email'>help@gachiviewer.shop</span>
        주소: 
      </div>
      <div className='escrow-inicis'>
        <img src={EscrowInicisPay} /> <span>안전구매(에스크로) 서비스 가맹점</span>
      </div>
    </FooterStyled>
  )
}

export default Footer